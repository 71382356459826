.article-tools {
  position: absolute;
  z-index: 10;
  width: 60px;
  top: 60px;

  @include breakpoint($toolButtonsDown) {
    right: 0;
    border-radius: 4px 0 0 4px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    transition: width .5s ease;
    background-color: $bg-color;

  }
  @include breakpoint($toolButtonsUp) {
    width: 80px;
    top: 15px;

    &:not(.sticky) {
      left: -60px;
    }
  }

  &.share {
    width: 80px;
  }

  &.sticky {
    position: fixed;

    @include breakpoint($toolButtonsDown) {
      right: 0;
      top: 70px;
    }
    @include breakpoint($largeUp) {
      top: 110px;
    }

    @include breakpoint($toolButtonsUp) {
      margin-left: -85px;
      top: 110px;
    }

  }
}

.tools-toggle {
  margin: 13px auto;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid $action-color;
  transition: background-color .2s ease;

  @include breakpoint($toolButtonsUp) {
    display: none;

  }

  .icon {
    display: block;
    position: relative;
    width: 3px;
    height: 3px;
    background-color: $action-color;
    transition: background-color .2s ease;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 3px;
      height: 3px;
      background-color: $action-color;
      transition: background-color .2s ease;
      position: absolute;
      top: 0;
    }

    &:before {
      left: -5px;
    }
    &:after {
      right: -5px;
    }
  }

  &.active {
    background-color: $action-color;

    .icon {
      background-color: $font-color-light;

      &:before,
      &:after {
        background-color: $font-color-light;
      }
    }
  }

  .device-desktop & {
    &:hover {
      background-color: $action-color;

      .icon {
        background-color: $font-color-light;

        &:before,
        &:after {
          background-color: $font-color-light;
        }
      }
    }
  }

}

.tools-content {
  @include breakpoint($toolButtonsDown) {
    height: 0;
    overflow: hidden;
  }
}

.tool-button {
  margin: 13px auto;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    display: block;

    path,
    polygon,
    ellipse {
      fill: $action-color;
    }
  }

  &.share {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  &.print {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  &.download {
    margin: 5px auto 15px auto;

    svg {
      width: 24px;
      height: 30px;
    }
  }

  &.mail {
    margin-bottom: 5px;
    svg {
      width: 26px;
      height: 20px;
    }
  }

  &.linkedin {
    margin-top: 0;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  &.twitter {
    svg {
      width: 23px;
      height: 18px;
    }
  }

  &.facebook {
    svg {
      width: 12px;
      height: 27px;
    }
  }

  .device-desktop & {
    cursor: pointer;

    svg {
      path,
      polygon,
      ellipse {
        transition: fill .2s ease;
      }
    }

    &:hover {
      svg {
        path,
        polygon,
        ellipse {
          fill: $primary-color;
        }
      }
    }
  }
}

.share-content {
  @include breakpoint($toolButtonsDown) {
    overflow: hidden;
    height: 0;
    transition: height .3s ease-out;
    background-color: $bg-color2;

    &.open {
      height: 193px;
    }
  }

  @include breakpoint($toolButtonsUp) {
    opacity: 0;
    visibility: visible;
    transition: all .2s ease-out;
    background-color: $action-color;
    position: absolute;
    top: 165px;
    left: 15px;
    width: 50px;
    border-radius: 4px;
    pointer-events: none;

    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent $action-color transparent;
      position: absolute;
      top: -10px;
      left: 15px;
    }


    .tool-button {
      svg {
        path,
        ellipse {
          fill: $font-color-light;
        }
      }
      &.mail {
        margin-top: 5px;
      }

      .device-desktop & {
        &:hover {
          svg {
            path,
            ellipse {
              fill: $bg-color-70;
            }
          }
        }
      }
    }

    &.open {
      pointer-events: auto;
      opacity: 1;
      visibility: visible;
      top: 155px;
    }
  }

}
