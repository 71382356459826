.hero-block {
  background-position: center center;
  background-size: cover;
  padding: 20px;
  position: relative;

  @include breakpoint($largeUp) {
    padding: 50px;
  }

  .content {
    position: relative;
    z-index: 2;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &:before {
    background-color: rgba(0,0,0,0.2);
  }
  &:after {
    background: linear-gradient(to bottom,rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
  }
}

.hero-title {
  margin-bottom: 20px;
  padding-bottom: 20px;
  text-align: center;
  position: relative;

  @include breakpoint($largeUp) {
    margin-bottom: 30px;
    padding-bottom: 30px;
  }

  &:after {
    content: '';
    height: 2px;
    width: 60px;
    background-color: $primary-color;
    position: absolute;
    left: calc(50% - 30px);
    bottom: 0;
  }
}
.hero-introduction {
  text-align: center;
  max-width: 670px;
  margin: 0 auto;
}
