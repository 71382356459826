
.page-title {
  margin: 0 0 25px 0;
}

.article-detail {
  .date {
    margin-bottom: 10px;
  }
  .page-title {
    @include breakpoint($largeDown) {
      padding-right: 80px;
    }
  }
}

.article-details,
.contact-details {
  display: block;
  border-bottom: 2px solid $action-color;

  li {
    display: flex;
    margin-bottom: 10px;

    @include breakpoint($mediumUp) {
      min-width: 300px;
    }

    &.authors {
      width: 100%;
      margin-bottom: 20px;

    }
  }
  .label {
    flex: 1 auto;
    max-width: 110px;
    @include font(main-bold);
  }
  .value {
    flex: 3 0;
  }

  //.download {
  //  margin-bottom: 20px;
  //
    //@include breakpoint($mediumUp) {
    //  position: absolute;
    //  bottom: 0;
    //  right: 0;
    //}
  //}
}

.article-details {
  @include breakpoint($mediumUp) {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    li {
      width: 100%;

      &:last-child {
        padding-bottom: 10px;
      }

      .label {
        max-width: 150px;
      }
    }
  }
}

.contact-details {
  .label {
    max-width: 275px;
  }

  li:last-child {
    padding-bottom: 40px;
  }
}

.contact-detail-item {
  display: block;
  position: relative;
  padding-left: 40px;

  &.phone {
    margin-top: 15px;
  }

  a {
    color: $action-color;
    text-decoration: none;

    .device-desktop & {
      transition: color .2s ease;

      &:hover {
        text-decoration: underline;
        color: $action-color-hover;

      }
    }
  }

  svg {
    display: block;
    position: absolute;
    left: 0;
    top: 0;

    path {
      fill: $action-color;
    }

    &.phone-icon {
      width: 24px;
      height: 23px;
    }
    &.mail-icon {
      width: 27px;
      height: 18px;
      top: 4px;
    }
  }
}

article {
  &.basic-page-detail {
    @include breakpoint($largeDown) {
      padding-top: 20px;
    }
  }
  &.has-toc {
    @include breakpoint($largeDown) {
      padding-top: 70px;
    }
  }

  h2 {
    font-size: 2.5rem;
    line-height: 2.8rem;

    &:not(.no-border) {
      margin: 0 0 5px 0;
      display: block;
      position: relative;
      padding-top: 20px;

      @include breakpoint($largeUp) {
        padding-top: 30px;
      }

      &:after {
        content: '';
        height: 2px;
        width: 60px;
        position: absolute;
        background-color: $action-color;
        left: 0;
        top: 0;
      }
    }
  }

  h3 {
    font-size: 2rem;
    line-height: 2.6rem;
  }
}

.caption {
  @include font(main-italic);
  text-align: right;
}
