footer {
  background-color: $bg-color-dark;
  color: $font-color-light;

  @include breakpoint($largeUp) {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }

  .column {
    @include breakpoint($largeUp) {
      padding: 30px 25px;
      display: flex;
      justify-content: space-between;
    }
  }

}

.footer-menu {
  @include breakpoint($largeDown) {
    padding: 20px 0 20px 0;
  }

  @include breakpoint($largeUp) {
    order: 2;
    width: 75%;
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
  }

  @include breakpoint($xlargeUp) {
    padding-left: 60px;
  }

  li {
    display: block;
  }
}

.footer-menu-block {

  @include breakpoint($largeDown) {
    &:first-child {
      .contact {
        display: none;
      }
    }
  }
  @include breakpoint($largeUp) {
    width: calc(33% - 30px);

    &:last-child {
      display: none;
    }
  }
}

.footer-submenu {
  @include breakpoint($largeDown) {
    height: 0;
    overflow: hidden;
  }
}

.footer-title {
  font-size: 1.8rem;
  display: block;
  padding: 10px 0;
  color: $font-color-light;
  text-decoration: none;
  @include font(heading);

  &.has-submenu {
    .label {
      display: inline-block;
      position: relative;
      padding-right: 25px;
    }

    .arrow-down {
      position: absolute;
      top: calc(50% - 4px);
      right: 0;
      width: 11px;
      transition: transform .2s ease-out;
      transform-origin: center 3px;

      &:before,
      &:after {
        content: '';
        display: block;
        width: 9px;
        height: 2px;
        position: absolute;
        top: 0;
        background-color: $font-color-light;
      }
      &:before {
        left: 0;
        transform-origin: left top;
        transform: rotate(45deg);
      }
      &:after {
        right: 0;
        transform-origin: right top;
        transform: rotate(-45deg);
      }
    }

    @include breakpoint($largeDown) {
      &.open {
        .arrow-down {
          transform: rotate(180deg);
        }
      }
    }
    @include breakpoint($largeUp) {
      cursor: default;

    }
  }
}

.footer-link {
  font-size: 1.7rem;
  display: block;
  padding: 10px 0;
  color: $font-color-light;
  text-decoration: none;

  .device-desktop & {
    &:not(.has-submenu) {
      transition: color .2s ease;

      &:hover {
        color: $primary-color;
      }
    }
  }
}

.footer-logo {
  padding-bottom: 10px;
  color: $font-color-light;

  @include breakpoint($largeDown) {
    padding-top: 30px;
    border-top: 2px solid $bg-color-20;
  }

  @include breakpoint($largeUp) {
    order: 1;
    width: 25%;
    border-right: 2px solid $bg-color-20;
  }

  svg {
    width: 94px;
    height: 26px; //IE FIX

    path,
    polygon {
      fill: $font-color-light;
      transition: fill 0.3s ease;
    }
  }

  .description {

    font-size: 1.4rem;
  }

  .copyright {
    font-size: 1.4rem;
    opacity: .3;

    pointer-events: none;
    text-decoration: none;
    color: inherit;
  }
}

.footer-logo-link:hover svg {

  path,
  polygon {
    fill: $bg-color-70;
  }
}

.footer-logo-link-pw svg {
  width: 130px;
  height: 24px; //IE FIX
  margin-top: 15px;
}
