.article-comments-form {
  .name,
  .email {
    max-width: 380px;
  }

  .form-actions {
    margin-top: -10px;

    .button {
      display: inline-block;
    }
  }

  input[type='submit'] {
    display: none;
  }
}

.comments {
  margin-top: 40px;
}

.comment {
  margin: 15px 0 20px 0;

  @include breakpoint($largeUp) {
    margin: 25px 0 30px 0;
  }

  &:not(:last-child) {
    display: block;
    position: relative;
    padding-bottom: 20px;

    @include breakpoint($largeUp) {
      padding-bottom: 30px;
    }

    &:after {
      content: '';
      height: 2px;
      width: 60px;
      position: absolute;
      background-color: $border-color-light;
      left: 0;
      bottom: 0;
    }
  }
}

.comment-name {
  margin-bottom: 10px;
}
.comment-messsage {
  margin-top: 10px;
}
