.block-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.block {
  margin: 15px 0;
  width: 100%;
  padding: 15px 20px 20px 20px;
  background-color: $bg-color2;
  position: relative;

  &.toc {
    background-color: $bg-color3;
  }

  @include breakpoint($largeUp) {
    padding: 25px 30px 30px 30px;
  }

  &.has-buttons {
    @include breakpoint($mediumUp) {
      padding-bottom: 75px;
    }

    @include breakpoint($largeUp) {
      padding-bottom: 90px;
    }
  }

  &.light {
    background-color: $bg-color;
  }

  &.full-width {
    margin: 10px 0;
    padding: 15px 0 30px 0;
    border-bottom: 2px solid $border-color-20;

    @include breakpoint($mediumUp) {

    }
    @include breakpoint($largeUp) {
      padding: 25px 0 40px 0;
    }
  }

  @include breakpoint($largeUp) {
    &.large-50 {
      width: calc(50% - 15px);
    }
    &.large-33 {
      width: calc(33% - 15px);
    }
  }


}

.date {
  text-transform: uppercase;
  font-size: 1.1rem;
  line-height: 1.1rem;
  letter-spacing: 2px;
  @include font(main-bold);

  .year {
    position: relative;
    padding-left: 15px;
    margin-left: 5px;
    display: inline-block;

    &:before {
      content: '';
      display: block;
      width: 2px;
      height: 2px;
      background-color: $font-color;
      position: absolute;
      top: 5px;
      left: 0;
    }
  }
}

.block-title {
  margin: 0 0 15px 0;
  display: block;
  position: relative;
  padding-bottom: 15px;

  @include breakpoint($largeUp) {
    margin-bottom: 25px;
    padding-bottom: 25px;
  }

  &:not(:first-child) {
    margin-top: 10px;

    @include breakpoint($largeUp) {
      margin-top: 15px;
    }
  }


  &:after {
    content: '';
    height: 2px;
    width: 60px;
    position: absolute;
    background-color: $action-color;
    left: 0;
    bottom: 0;
  }

  &.has-comments {
    position: relative;
    padding-right: 50px;

    @include breakpoint($largeUp) {
      padding-right: 60px;
    }
  }
}

.article-comments-count {
  display: block;
  position: absolute;
  top: -15px;
  right: 0;

  @include breakpoint($largeUp) {
    top: -25px;
  }

  .count {
    position: absolute;
    width: 100%;
    height: 100%;
    line-height: 32px;
    text-align: center;
    color: $action-color;
    font-size: 1.8rem;
    @include font(heading);
  }
}
.comment-icon {
  display: block;
  width: 41px;
  height: 42px;

  path {
    fill: $action-color;
  }

}

.block-content {
  .full-width & {
    @include breakpoint($mediumUp) {
      display: flex;
      justify-content: space-between;
    }
  }
}

.block-details {

  .full-width & {
    @include breakpoint($mediumUp) {
      flex: 70 0px;
      padding-right: 30px;
    }
  }

  li {
    display: flex;
    margin-bottom: 10px;
  }
  .label {
    flex: 1 auto;
    max-width: 120px;
    @include font(main-bold);
  }
  .value {
    flex: 3 0;
  }
}

.block-controls {
  margin-top: 20px;

  .has-buttons & {
    @include breakpoint($mediumUp) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: absolute;
      bottom: 20px;
      width: calc(100% - 40px);

      .button-show-abstract,
      .download {
        margin-bottom: 0;
      }
    }
    @include breakpoint($largeUp) {
      bottom: 30px;
      width: calc(100% - 60px);
    }
  }

  .full-width & {
    @include breakpoint($mediumUp) {
      margin: 0;
      flex: 1 auto;
    }
  }


  .button-show-abstract,
  .download {
    margin-bottom: 15px;
  }
}
