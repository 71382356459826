@charset "UTF-8";
* {
  margin: 0;
  padding: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal; }

ul {
  list-style: none; }

button {
  -webkit-appearance: none;
  background: transparent;
  border: none;
  outline: none; }

html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

img,
iframe,
video {
  height: auto;
  max-width: 800px;
  width: auto; }

iframe {
  border: 0; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSansBlackItalic.eot");
  src: url("../fonts/OpenSansBlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansBlackItalic.woff") format("woff"), url("../fonts/OpenSansBlackItalic.ttf") format("truetype"), url("../fonts/OpenSansBlackItalic.svg#OpenSansBlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+000D-25CA; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSansBold.eot");
  src: url("../fonts/OpenSansBold.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansBold.woff") format("woff"), url("../fonts/OpenSansBold.ttf") format("truetype"), url("../fonts/OpenSansBold.svg#OpenSansBold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+000D-25CA; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSansBoldItalic.eot");
  src: url("../fonts/OpenSansBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansBoldItalic.woff") format("woff"), url("../fonts/OpenSansBoldItalic.ttf") format("truetype"), url("../fonts/OpenSansBoldItalic.svg#OpenSansBoldItalic") format("svg");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+000D-25CA; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSansSemibold.eot");
  src: url("../fonts/OpenSansSemibold.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansSemibold.woff") format("woff"), url("../fonts/OpenSansSemibold.ttf") format("truetype"), url("../fonts/OpenSansSemibold.svg#OpenSansSemibold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+000D-25CA; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSansSemiboldItalic.eot");
  src: url("../fonts/OpenSansSemiboldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansSemiboldItalic.woff") format("woff"), url("../fonts/OpenSansSemiboldItalic.ttf") format("truetype"), url("../fonts/OpenSansSemiboldItalic.svg#OpenSansSemiboldItalic") format("svg");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+000D-25CA; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSansLight.eot");
  src: url("../fonts/OpenSansLight.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansLight.woff") format("woff"), url("../fonts/OpenSansLight.ttf") format("truetype"), url("../fonts/OpenSansLight.svg#OpenSansLight") format("svg");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+000D-25CA; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSansLightItalic.eot");
  src: url("../fonts/OpenSansLightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansLightItalic.woff") format("woff"), url("../fonts/OpenSansLightItalic.ttf") format("truetype"), url("../fonts/OpenSansLightItalic.svg#OpenSansLightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+000D-25CA; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans.eot");
  src: url("../fonts/OpenSans.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans.woff") format("woff"), url("../fonts/OpenSans.ttf") format("truetype"), url("../fonts/OpenSans.svg#OpenSans") format("svg");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+000D-25CA; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSansItalic.eot");
  src: url("../fonts/OpenSansItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansItalic.woff") format("woff"), url("../fonts/OpenSansItalic.ttf") format("truetype"), url("../fonts/OpenSansItalic.svg#OpenSansItalic") format("svg");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+000D-25CA; }

@font-face {
  font-family: "Meta Lf";
  src: url("../fonts/MetaLF.eot");
  src: url("../fonts/MetaLF.eot?#iefix") format("embedded-opentype"), url("../fonts/MetaLF.woff2") format("woff2"), url("../fonts/MetaLF.woff") format("woff"), url("../fonts/MetaLF.svg#MetaLF") format("svg");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE; }

html {
  font-size: 62.5%;
  height: 100%; }
  @media screen and (max-width: 399px) {
    html {
      font-size: 55%; } }

body {
  height: 100%;
  background: #ffffff;
  color: #202a38;
  position: relative;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.6rem;
  line-height: 2.5rem;
  overflow-x: hidden;
  overflow-y: auto; }

#root {
  min-height: 100%;
  position: relative; }
  .sticky-header #root {
    padding-top: 50px; }
    @media screen and (min-width: 1000px) {
      .sticky-header #root {
        padding-top: 93px;
        padding-bottom: 260px; } }

main {
  min-height: 400px; }
  main section:last-child {
    margin-bottom: 0; }

.column {
  position: relative;
  width: 100%;
  max-width: 1290px;
  margin: 0 auto;
  padding: 0 25px; }

.article-two-column {
  padding: 0; }
  @media screen and (min-width: 1000px) {
    .article-two-column {
      padding: 30px 25px 0 25px;
      display: flex;
      justify-content: space-between; } }
  .article-two-column aside {
    z-index: 2; }
    @media screen and (min-width: 1000px) {
      .article-two-column aside {
        max-width: 370px;
        width: calc(33% - 40px); } }
  .article-two-column article {
    padding: 0 25px; }
    @media screen and (min-width: 1000px) {
      .article-two-column article {
        padding: 0;
        max-width: 800px;
        width: calc(67% - 40px); } }

b,
strong {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-style: normal; }

em {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: italic; }

strong em,
b em,
em strong,
em b {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-style: italic; }

h1,
h2,
h3,
h4 {
  font-family: "Meta Lf", sans-serif;
  font-weight: 500;
  font-style: normal; }

p {
  margin: 1rem 0; }

pre,
code {
  font-size: 1.6rem;
  padding: 0;
  display: block;
  white-space: normal; }

::selection {
  color: #ffffff;
  background: #e85811; }

::-moz-selection {
  color: #ffffff;
  background: #e85811; }

ul:not(.plain) {
  margin: 1rem 0;
  padding: 0; }
  ul:not(.plain) > li {
    list-style: none;
    position: relative;
    padding-left: 15px; }
    ul:not(.plain) > li:before {
      content: '';
      display: block;
      width: 4px;
      height: 4px;
      background-color: #202a38;
      border-radius: 4px;
      position: absolute;
      top: 10px;
      left: 0; }
  ul:not(.plain) ul {
    margin-left: 5px; }
    ul:not(.plain) ul li:before {
      border-radius: 0;
      width: 6px;
      height: 2px;
      top: 12px; }

ul.plain {
  margin: 0;
  padding: 0;
  list-style: none; }

ol {
  margin: 1rem 0;
  padding: 0 0 0 25px; }

.table-wrapper {
  margin: 2rem 0;
  display: block;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

button {
  padding: 0; }

.toc-link p {
  margin: 0px; }

object {
  max-width: 100%; }

*,
*:before,
*:after {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.hidden {
  display: none; }

sub, sup {
  line-height: 0; }

h1 {
  font-size: 2.6rem;
  line-height: 2.9rem; }
  @media screen and (min-width: 1000px) {
    h1 {
      font-size: 3.0rem;
      line-height: 3.6rem; } }

h2 {
  font-size: 2.6rem;
  line-height: 2.9rem; }
  @media screen and (min-width: 1000px) {
    h2 {
      font-size: 3.0rem;
      line-height: 3.6rem; } }

h3 {
  font-size: 2.0rem;
  line-height: 2.4rem; }
  @media screen and (min-width: 1000px) {
    h3 {
      font-size: 2.4rem;
      line-height: 2.8rem; } }

h4 {
  font-size: 1.8rem;
  line-height: 2.2rem; }
  @media screen and (min-width: 1000px) {
    h4 {
      font-size: 2.0rem;
      line-height: 2.6rem; } }

.text-light {
  color: #ffffff; }

.button-wrapper {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between; }
  .button-wrapper .button {
    width: calc(50% - 15px); }

.button {
  font-size: 1.8rem;
  line-height: 2.0rem;
  display: block;
  cursor: pointer;
  text-decoration: none;
  font-family: "Meta Lf", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #ffffff;
  border-radius: 3px;
  padding: 10px 20px;
  text-align: center; }
  .button.arrow {
    position: relative;
    padding-right: 45px; }
  .button.download {
    position: relative;
    padding-left: 55px; }
  .button .arrow-down {
    position: absolute;
    top: calc(50% - 3px);
    right: 20px;
    width: 11px;
    background-color: rgba(255, 255, 255, 0.2); }
    .button .arrow-down:before, .button .arrow-down:after {
      content: '';
      display: block;
      width: 9px;
      height: 2px;
      position: absolute;
      top: 0; }
    .button .arrow-down:before {
      left: 0;
      transform-origin: left top;
      transform: rotate(45deg); }
    .button .arrow-down:after {
      right: 0;
      transform-origin: right top;
      transform: rotate(-45deg); }
  .button .download {
    width: 44px;
    height: 100%;
    border-right: 2px solid rgba(255, 255, 255, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center; }
    .button .download svg {
      width: 23px;
      height: 19px; }
      .button .download svg path {
        fill: #ffffff; }
  .device-desktop .button {
    transition: background-color .3s ease, color .3s ease; }
  .button.base {
    background-color: #0e9c97; }
    .device-desktop .button.base:hover {
      background-color: #10b6b0; }
  .button.secondary {
    background-color: #03639f; }
    .device-desktop .button.secondary:hover {
      background-color: #2779ad; }
  .button.action {
    background-color: #e85811; }
    .device-desktop .button.action:hover {
      background-color: #ff7a22; }
  .button.ghost {
    background-color: transparent;
    padding-top: 8px;
    padding-bottom: 8px; }
    .device-desktop .button.ghost .arrow-down:before, .device-desktop .button.ghost .arrow-down:after {
      transition: background-color .3s ease; }
    .device-desktop .button.ghost .download {
      transition: border-color .3s ease; }
      .device-desktop .button.ghost .download svg path {
        transition: fill .3s ease; }
    .button.ghost.base {
      color: #0e9c97;
      border: 2px solid #0e9c97; }
      .button.ghost.base .arrow-down:before, .button.ghost.base .arrow-down:after {
        background-color: #0e9c97; }
      .button.ghost.base .download {
        border-color: rgba(14, 156, 151, 0.2); }
        .button.ghost.base .download svg path {
          fill: #0e9c97; }
      .device-desktop .button.ghost.base:hover {
        color: #ffffff;
        background-color: #0e9c97; }
        .device-desktop .button.ghost.base:hover .arrow-down:before, .device-desktop .button.ghost.base:hover .arrow-down:after {
          background-color: #ffffff; }
        .device-desktop .button.ghost.base:hover .download {
          border-color: rgba(255, 255, 255, 0.2); }
          .device-desktop .button.ghost.base:hover .download svg path {
            fill: #ffffff; }
    .button.ghost.secondary {
      color: #03639f;
      border: 2px solid #03639f; }
      .button.ghost.secondary .arrow-down:before, .button.ghost.secondary .arrow-down:after {
        background-color: #03639f; }
      .button.ghost.secondary .download {
        border-color: rgba(3, 99, 159, 0.2); }
        .button.ghost.secondary .download svg path {
          fill: #03639f; }
      .device-desktop .button.ghost.secondary:hover {
        color: #ffffff;
        background-color: #03639f; }
        .device-desktop .button.ghost.secondary:hover .arrow-down:before, .device-desktop .button.ghost.secondary:hover .arrow-down:after {
          background-color: #ffffff; }
        .device-desktop .button.ghost.secondary:hover .download {
          border-color: rgba(255, 255, 255, 0.2); }
          .device-desktop .button.ghost.secondary:hover .download svg path {
            fill: #ffffff; }
    .button.ghost.action {
      color: #e85811;
      border: 2px solid #e85811; }
      .button.ghost.action .arrow-down:before, .button.ghost.action .arrow-down:after {
        background-color: #e85811; }
      .button.ghost.action .download {
        border-color: rgba(232, 88, 17, 0.2); }
        .button.ghost.action .download svg path {
          fill: #e85811; }
      .device-desktop .button.ghost.action:hover {
        color: #ffffff;
        background-color: #e85811; }
        .device-desktop .button.ghost.action:hover .arrow-down:before, .device-desktop .button.ghost.action:hover .arrow-down:after {
          background-color: #ffffff; }
        .device-desktop .button.ghost.action:hover .download {
          border-color: rgba(255, 255, 255, 0.2); }
          .device-desktop .button.ghost.action:hover .download svg path {
            fill: #ffffff; }

.text-link {
  text-decoration: none;
  color: #e85811;
  display: inline-block;
  word-break: break-word;
  line-height: 2.2rem;
  border-bottom: 1px solid transparent;
  margin-top: 15px; }
  .device-desktop .text-link {
    transition: border-color .3s ease; }
    .device-desktop .text-link .arrow-right {
      transition: margin .3s ease-out; }
    .device-desktop .text-link:hover {
      border-color: #e85811; }
      .device-desktop .text-link:hover .arrow-right {
        margin-left: 15px;
        margin-right: -5px; }
  .text-link .arrow-right {
    display: inline-block;
    position: relative;
    height: 10px;
    margin-left: 10px; }
    .text-link .arrow-right:before, .text-link .arrow-right:after {
      content: '';
      display: block;
      width: 7px;
      height: 1px;
      position: absolute;
      right: 0;
      background-color: #e85811; }
    .text-link .arrow-right:before {
      top: 0;
      transform-origin: left top;
      transform: rotate(45deg); }
    .text-link .arrow-right:after {
      bottom: 0;
      transform-origin: left bottom;
      transform: rotate(-45deg); }

.button:after {
  filter: brightness(100) !important;
  top: 12px !important;
  right: 15px !important; }

.external-link {
  display: inline-block;
  word-break: break-word;
  position: relative;
  padding-right: 25px;
  margin-right: 5px; }
  .external-link:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    right: 0;
    width: 17px;
    height: 16px;
    background-image: url("../images/icons/external-link.svg");
    background-size: cover; }
  .external-link.button-readmore {
    padding-right: 41px; }

.exclude-external-link {
  display: inline-block;
  margin-top: 10px; }

.title-link {
  text-decoration: none;
  color: #202a38;
  display: block; }
  .device-desktop .title-link {
    transition: color .3s ease; }
    .device-desktop .title-link:hover {
      color: #0e9c97; }

.close-button {
  display: block;
  padding: 10px; }
  .close-button .icon {
    display: block;
    position: relative;
    width: 15px;
    height: 15px; }
    .close-button .icon:before, .close-button .icon:after {
      content: '';
      display: block;
      width: 23px;
      height: 2px;
      position: absolute;
      top: 0;
      background-color: #202a38; }
    .close-button .icon:before {
      left: 0;
      transform-origin: left top;
      transform: rotate(45deg); }
    .close-button .icon:after {
      right: 0;
      transform-origin: right top;
      transform: rotate(-45deg); }
  .close-button.light .icon:before, .close-button.light .icon:after {
    background-color: #ffffff; }
  .device-desktop .close-button .icon:before, .device-desktop .close-button .icon:after {
    transition: background-color .2s ease; }
  .device-desktop .close-button:hover .icon:before, .device-desktop .close-button:hover .icon:after {
    background-color: #0e9c97; }
  .device-desktop .close-button:hover.light .icon:before, .device-desktop .close-button:hover.light .icon:after {
    background-color: rgba(255, 255, 255, 0.7); }

.back-to-top {
  width: 44px;
  height: 44px; }
  @media screen and (max-width: 999px) {
    .back-to-top {
      position: fixed;
      bottom: 15px;
      right: 15px; } }
  @media screen and (min-width: 1000px) {
    .back-to-top {
      position: absolute;
      right: 25px;
      top: 0; } }
  @media screen and (min-width: 1400px) {
    .back-to-top {
      right: -50px; } }

.back-to-top-button {
  width: inherit;
  height: inherit;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  border-radius: 3px;
  background-color: #0e9c97;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all .3s ease;
  z-index: 50; }
  @media screen and (min-width: 1000px) {
    .back-to-top-button {
      position: fixed;
      bottom: 25px; } }
  @media screen and (min-width: 1400px) {
    .back-to-top-button {
      bottom: 30px; } }
  .back-to-top-button.visible {
    visibility: visible;
    opacity: 1; }
  .back-to-top-button .arrow-up {
    position: relative;
    display: block;
    width: 14px;
    height: 8px; }
    .back-to-top-button .arrow-up:before, .back-to-top-button .arrow-up:after {
      content: '';
      display: block;
      width: 9px;
      height: 2px;
      position: absolute;
      bottom: 0;
      background-color: #ffffff; }
    .back-to-top-button .arrow-up:before {
      left: 0;
      transform-origin: left top;
      transform: rotate(-45deg); }
    .back-to-top-button .arrow-up:after {
      right: 0;
      transform-origin: right top;
      transform: rotate(45deg); }
  .device-desktop .back-to-top-button {
    transition: background-color .2s ease; }
    .device-desktop .back-to-top-button:hover {
      background-color: #10b6b0; }

fieldset {
  border: none;
  outline: none;
  appearance: none;
  position: relative; }

.form-item-wrapper {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media screen and (min-width: 1000px) {
    .form-item-wrapper {
      margin: 10px 0 30px 0; } }
  .form-item-wrapper .form-item {
    margin: 0 0 25px 0; }
    @media screen and (min-width: 1000px) {
      .form-item-wrapper .form-item {
        margin: 0; } }
    .form-item-wrapper .form-item:last-child {
      margin: 0; }

.form-item {
  position: relative;
  width: 100%;
  margin: 25px 0; }
  @media screen and (min-width: 1000px) {
    .form-item.large-50 {
      width: calc(50% - 15px); } }

.form-text {
  display: block;
  text-align: right;
  font-size: 1.4rem; }

input[type='text'],
input[type='email'] {
  background-color: #ffffff;
  border-radius: 3px;
  border: 2px solid #d9d9d9;
  appearance: none;
  outline: none;
  font-size: 1.6rem;
  line-height: 1.6rem;
  width: 100%;
  padding: 10px 15px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  transition: border-color .2s ease; }
  input[type='text'].search-input,
  input[type='email'].search-input {
    background-color: #f5f5f5;
    border: none;
    height: 45px; }
    @media screen and (min-width: 1000px) {
      input[type='text'].search-input,
      input[type='email'].search-input {
        padding: 11px 25px; } }

textarea {
  background-color: #ffffff;
  border-radius: 3px;
  border: 2px solid #d9d9d9;
  appearance: none;
  outline: none;
  font-size: 1.6rem;
  line-height: 1.6rem;
  width: 100%;
  padding: 10px 15px;
  resize: none;
  height: 150px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  transition: border-color .2s ease; }

select {
  appearance: none;
  background-color: #f5f5f5;
  border-radius: 3px;
  outline: none;
  border: none;
  color: #0e9c97;
  font-size: 1.6rem;
  line-height: 1.9rem;
  padding: 10px 15px;
  width: 100%;
  position: relative; }
  @media screen and (min-width: 1000px) {
    select {
      padding: 12px 20px; } }

.select-wrapper {
  display: inline-block;
  position: relative; }
  .device-desktop .select-wrapper:before, .device-desktop .select-wrapper:after {
    transition: background-color .2s ease; }
  .device-desktop .select-wrapper select {
    transition: color .2s ease; }
  .device-desktop .select-wrapper:hover:before, .device-desktop .select-wrapper:hover:after {
    background-color: #03639f; }
  .device-desktop .select-wrapper:hover.sort:hover:before, .device-desktop .select-wrapper:hover.sort:hover:after {
    background-color: white; }
  .device-desktop .select-wrapper:hover select {
    color: #03639f; }
  .select-wrapper select {
    padding-right: 40px; }
  .select-wrapper:before, .select-wrapper:after {
    content: '';
    display: block;
    width: 7px;
    height: 1px;
    position: absolute;
    top: calc(50% - 2px);
    background-color: #0e9c97;
    z-index: 2; }
  .select-wrapper:before {
    right: 22px;
    transform-origin: left top;
    transform: rotate(45deg); }
  .select-wrapper:after {
    right: 20px;
    transform-origin: right top;
    transform: rotate(-45deg); }

textarea:invalid,
input:invalid {
  box-shadow: none;
  outline: none; }

select::-ms-expand {
  display: none; }

::-webkit-input-placeholder {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
  color: #202a38;
  transition: color .2s ease-out; }

:-moz-placeholder {
  /* Firefox 18- */
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
  color: #202a38;
  transition: color .2s ease-out; }

::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
  color: #202a38;
  transition: color .2s ease-out; }

:-ms-input-placeholder {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
  color: #202a38;
  transition: color .2s ease-out; }

:focus::-webkit-input-placeholder {
  color: transparent; }

:focus::-moz-placeholder {
  color: transparent; }

:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px white inset;
  border-color: #dedfe1 !important; }

.message-wrapper {
  margin: 30px 0; }
  .message-wrapper .successMessage, .message-wrapper .errorMessage {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    background-color: #f5f5f5;
    padding: 10px 25px; }
  .message-wrapper .errorMessage {
    color: #d61739; }

.form-message-wrapper {
  position: absolute;
  left: 5px;
  top: -33px;
  height: 33px;
  background-color: #ffffff;
  opacity: 1;
  visibility: visible;
  transition: all .2s ease;
  padding: 10px 15px;
  font-size: 1.4rem;
  line-height: 1.6rem;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15); }
  .form-message-wrapper.mod-hidden {
    opacity: 0;
    visibility: hidden; }
  .form-message-wrapper.error {
    color: #d61739; }
    .form-message-wrapper.error:not(.mod-hidden) + input,
    .form-message-wrapper.error:not(.mod-hidden) + textarea {
      border-color: #d61739; }

header {
  position: relative;
  z-index: 20;
  background-color: #ffffff;
  height: 50px;
  border-bottom: 2px solid #0e9c97; }
  @media screen and (min-width: 1000px) {
    header {
      height: 93px; } }
  .sticky-header header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%; }

.home-link {
  position: absolute;
  top: 4px;
  left: 5px;
  text-decoration: none;
  color: #202a38; }
  @media screen and (min-width: 1000px) {
    .home-link {
      top: 7px;
      left: 25px; } }
  .home-link .logo {
    width: 78px;
    height: 41px; }
    @media screen and (min-width: 1000px) {
      .home-link .logo {
        width: 155px;
        height: 80px; } }
  .home-link .tagline {
    display: none;
    position: absolute;
    top: 22px;
    left: 166px;
    font-size: 1.3rem;
    line-height: 1.7rem;
    width: 170px; }
    @media screen and (min-width: 1100px) {
      .home-link .tagline {
        display: block; } }

.menu-icon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #0e9c97;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (min-width: 1000px) {
    .menu-icon {
      width: 44px;
      height: 44px; } }
  .menu-icon svg {
    display: block; }
    .menu-icon svg path {
      fill: #ffffff; }
  .device-desktop .menu-icon {
    transition: background-color .2s ease; }
    .device-desktop .menu-icon:hover {
      background-color: #10b6b0; }

.menu-toggle {
  position: absolute;
  top: 6px;
  right: 6px; }
  .menu-toggle .icon {
    position: relative;
    display: block;
    width: 14px;
    height: 2px;
    background-color: #ffffff;
    transition: background-color .2s ease; }
    .menu-toggle .icon:before, .menu-toggle .icon:after {
      content: '';
      display: block;
      width: 14px;
      height: 2px;
      background-color: #ffffff;
      position: absolute;
      left: 0;
      transition: all .15s ease-out; }
    .menu-toggle .icon:before {
      top: -5px;
      transform-origin: left top; }
    .menu-toggle .icon:after {
      bottom: -5px;
      transform-origin: left bottom; }
  .menu-toggle.active .icon {
    background-color: transparent; }
    .menu-toggle.active .icon:before {
      left: 2px;
      width: 18px;
      top: -6px;
      transform: rotate(45deg); }
    .menu-toggle.active .icon:after {
      left: 2px;
      width: 18px;
      bottom: -6px;
      transform: rotate(-45deg); }
  @media screen and (min-width: 1000px) {
    .menu-toggle {
      display: none; } }

.home-button {
  position: absolute; }
  @media screen and (max-width: 1279px) {
    .home-button {
      display: none; } }
  @media screen and (max-width: 999px) {
    .home-button {
      margin-right: 6px;
      margin-left: 6px;
      display: flex;
      top: 6px;
      right: 45px; } }
  @media screen and (min-width: 1280px) {
    .home-button {
      top: 25px;
      right: 90px; } }

@media screen and (max-width: 999px) {
  .search-toggle {
    margin-right: 6px;
    margin-left: 6px;
    position: absolute;
    top: 6px;
    right: 90px; } }

@media screen and (min-width: 1000px) {
  .search-toggle {
    position: absolute;
    top: 25px;
    right: 25px; } }

.search-menu {
  background-color: #0e9c97;
  width: 100%;
  max-width: 520px;
  border-radius: 0 0 5px 5px;
  position: absolute;
  right: 0;
  top: 50px;
  padding: 10px 55px 10px 10px;
  opacity: 0;
  visibility: hidden;
  transition: all .2s ease; }
  @media screen and (min-width: 1000px) {
    .search-menu {
      right: 17px;
      top: 92px; } }
  .search-menu.open {
    visibility: visible;
    opacity: 1; }
  .search-menu .close-button {
    position: absolute;
    top: 15px;
    right: 10px; }

footer {
  background-color: #202a38;
  color: #ffffff; }
  @media screen and (min-width: 1000px) {
    footer {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0; } }
  @media screen and (min-width: 1000px) {
    footer .column {
      padding: 30px 25px;
      display: flex;
      justify-content: space-between; } }

@media screen and (max-width: 999px) {
  .footer-menu {
    padding: 20px 0 20px 0; } }

@media screen and (min-width: 1000px) {
  .footer-menu {
    order: 2;
    width: 75%;
    display: flex;
    justify-content: space-between;
    padding-left: 30px; } }

@media screen and (min-width: 1200px) {
  .footer-menu {
    padding-left: 60px; } }

.footer-menu li {
  display: block; }

@media screen and (max-width: 999px) {
  .footer-menu-block:first-child .contact {
    display: none; } }

@media screen and (min-width: 1000px) {
  .footer-menu-block {
    width: calc(33% - 30px); }
    .footer-menu-block:last-child {
      display: none; } }

@media screen and (max-width: 999px) {
  .footer-submenu {
    height: 0;
    overflow: hidden; } }

.footer-title {
  font-size: 1.8rem;
  display: block;
  padding: 10px 0;
  color: #ffffff;
  text-decoration: none;
  font-family: "Meta Lf", sans-serif;
  font-weight: 500;
  font-style: normal; }
  .footer-title.has-submenu .label {
    display: inline-block;
    position: relative;
    padding-right: 25px; }
  .footer-title.has-submenu .arrow-down {
    position: absolute;
    top: calc(50% - 4px);
    right: 0;
    width: 11px;
    transition: transform .2s ease-out;
    transform-origin: center 3px; }
    .footer-title.has-submenu .arrow-down:before, .footer-title.has-submenu .arrow-down:after {
      content: '';
      display: block;
      width: 9px;
      height: 2px;
      position: absolute;
      top: 0;
      background-color: #ffffff; }
    .footer-title.has-submenu .arrow-down:before {
      left: 0;
      transform-origin: left top;
      transform: rotate(45deg); }
    .footer-title.has-submenu .arrow-down:after {
      right: 0;
      transform-origin: right top;
      transform: rotate(-45deg); }
  @media screen and (max-width: 999px) {
    .footer-title.has-submenu.open .arrow-down {
      transform: rotate(180deg); } }
  @media screen and (min-width: 1000px) {
    .footer-title.has-submenu {
      cursor: default; } }

.footer-link {
  font-size: 1.7rem;
  display: block;
  padding: 10px 0;
  color: #ffffff;
  text-decoration: none; }
  .device-desktop .footer-link:not(.has-submenu) {
    transition: color .2s ease; }
    .device-desktop .footer-link:not(.has-submenu):hover {
      color: #0e9c97; }

.footer-logo {
  padding-bottom: 10px;
  color: #ffffff; }
  @media screen and (max-width: 999px) {
    .footer-logo {
      padding-top: 30px;
      border-top: 2px solid rgba(255, 255, 255, 0.2); } }
  @media screen and (min-width: 1000px) {
    .footer-logo {
      order: 1;
      width: 25%;
      border-right: 2px solid rgba(255, 255, 255, 0.2); } }
  .footer-logo svg {
    width: 94px;
    height: 26px; }
    .footer-logo svg path,
    .footer-logo svg polygon {
      fill: #ffffff;
      transition: fill 0.3s ease; }
  .footer-logo .description {
    font-size: 1.4rem; }
  .footer-logo .copyright {
    font-size: 1.4rem;
    opacity: .3;
    pointer-events: none;
    text-decoration: none;
    color: inherit; }

.footer-logo-link:hover svg path,
.footer-logo-link:hover svg polygon {
  fill: rgba(255, 255, 255, 0.7); }

.footer-logo-link-pw svg {
  width: 130px;
  height: 24px;
  margin-top: 15px; }

section {
  margin: 25px 0; }
  @media screen and (min-width: 1000px) {
    section {
      margin: 40px 0; } }
  section.light {
    background-color: #f5f5f5;
    padding: 25px 0; }
    @media screen and (min-width: 1000px) {
      section.light {
        padding: 40px 0; } }
  @media screen and (min-width: 1000px) {
    section h2 {
      margin-bottom: 10px; } }

.hero {
  margin-top: 0; }
  body.page-home .hero {
    margin-top: 25px; }
    @media screen and (min-width: 1000px) {
      body.page-home .hero {
        margin-top: 40px; } }

.article-sections {
  margin-bottom: 20px; }
  @media screen and (min-width: 1000px) {
    .article-sections {
      margin-bottom: 50px; } }
  .article-sections section {
    margin: 20px 0; }
    @media screen and (min-width: 1000px) {
      .article-sections section {
        margin: 30px 0; } }
    .article-sections section:first-child h2 {
      padding-top: 0; }
      .article-sections section:first-child h2:after {
        display: none; }
    .article-sections section.article-section-subtitle {
      margin-bottom: 0; }
      .article-sections section.article-section-subtitle + section {
        margin-top: 10px; }
  .article-sections a:not(.button) {
    color: #e85811;
    text-decoration: none; }
    .device-desktop .article-sections a:not(.button) {
      transition: color .2s ease; }
      .device-desktop .article-sections a:not(.button):hover {
        text-decoration: underline;
        color: #ff7a22; }
  .article-sections ol li:not(:last-child),
  .article-sections ul li:not(:last-child) {
    padding-bottom: 5px; }
  .article-sections blockquote {
    margin: 15px 0;
    quotes: "“" "”" "‘" "’";
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: 2.0rem;
    line-height: 2.4rem; }
    .article-sections blockquote:before {
      content: open-quote;
      font-size: 4rem;
      line-height: 0.1rem;
      margin-right: 0.25em;
      vertical-align: -0.4em; }
    .article-sections blockquote:after {
      content: close-quote;
      font-size: 4rem;
      line-height: 0.1rem;
      margin-right: 0.25em;
      vertical-align: -0.4em; }
    .article-sections blockquote p {
      display: inline; }
  .article-sections .image-wrapper {
    display: inline-block; }
    .article-sections .image-wrapper .image {
      line-height: 0; }
  .article-sections .video-wrapper {
    position: relative;
    display: block;
    padding-bottom: 56.25%;
    line-height: 0;
    width: 100%; }
    .article-sections .video-wrapper iframe {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .article-sections a {
    word-wrap: break-word;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto; }

.article-section-title {
  margin-bottom: 0; }
  .article-section-title + section:not(.article-section-title) {
    margin-top: 0; }

.articles-header {
  margin-bottom: 0; }

.archive-index-content,
.article-index-content,
.search-results {
  margin-top: 0; }

@media screen and (min-width: 600px) {
  .latest-articles {
    position: relative; } }

@media screen and (min-width: 600px) {
  .latest-articles .all-articles-link {
    position: absolute;
    top: 5px;
    right: 25px; } }

@media screen and (min-width: 1000px) {
  .latest-articles .all-articles-link {
    top: 10px; } }

.articles-footer {
  padding-bottom: 20px; }
  @media screen and (min-width: 1000px) {
    .articles-footer {
      padding-bottom: 40px; } }

.article-section-video {
  text-align: center; }

.notfound {
  min-height: 300px; }

@media screen and (max-width: 999px) {
  .main-menu {
    position: fixed;
    top: 20px;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    background-color: #ffffff;
    z-index: 100;
    padding-top: 15px;
    visibility: hidden;
    opacity: 0;
    transition: all .15s ease-out; }
    .main-menu.open {
      visibility: visible;
      opacity: 1;
      top: 50px; } }

@media screen and (min-width: 1000px) {
  .main-menu {
    display: block;
    float: right;
    margin-right: 60px; } }

@media screen and (min-width: 1280px) {
  .main-menu {
    margin-right: 130px; } }

.main-menu li {
  position: relative; }
  @media screen and (min-width: 1000px) {
    .main-menu li {
      display: inline-block; } }

@media screen and (min-width: 1000px) {
  .device-desktop .main-menu .level-1:hover .sub-menu {
    visibility: visible;
    opacity: 1; }
  .device-desktop .main-menu .level-1:hover .menu-link {
    background-color: #03639f;
    color: #ffffff; }
    .device-desktop .main-menu .level-1:hover .menu-link .arrow-down:before, .device-desktop .main-menu .level-1:hover .menu-link .arrow-down:after {
      background-color: #ffffff; } }

.menu-link {
  font-family: "Meta Lf", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1.9rem;
  color: #202a38;
  text-decoration: none;
  display: block;
  transition: background-color .2s ease, color .2s ease; }
  @media screen and (max-width: 999px) {
    .menu-link {
      padding: 15px 25px; } }
  @media screen and (min-width: 1000px) {
    .menu-link {
      border-radius: 5px 5px 0 0;
      margin-top: 15px;
      padding: 20px 20px 31px 20px; } }
  @media screen and (min-width: 1200px) {
    .menu-link {
      padding: 20px 25px 31px 25px; } }
  @media screen and (max-width: 999px) {
    .menu-link.active {
      color: #0e9c97; } }
  @media screen and (min-width: 1000px) {
    .menu-link.active {
      background-color: #edecec; } }
  .menu-link.has-submenu {
    cursor: default; }
    .menu-link.has-submenu .label {
      display: inline-block;
      position: relative;
      padding-right: 25px; }
    .menu-link.has-submenu .arrow-down {
      position: absolute;
      top: calc(50% - 3px);
      right: 0;
      width: 11px;
      transition: transform .2s ease-out;
      transform-origin: center 3px; }
      .menu-link.has-submenu .arrow-down:before, .menu-link.has-submenu .arrow-down:after {
        content: '';
        display: block;
        width: 9px;
        height: 2px;
        position: absolute;
        top: 0;
        background-color: #202a38; }
      .menu-link.has-submenu .arrow-down:before {
        left: 0;
        transform-origin: left top;
        transform: rotate(45deg); }
      .menu-link.has-submenu .arrow-down:after {
        right: 0;
        transform-origin: right top;
        transform: rotate(-45deg); }
    .menu-link.has-submenu.open {
      background-color: #f5f5f5; }
      .menu-link.has-submenu.open .arrow-down {
        transform: rotate(180deg); }
  .device-desktop .menu-link:not(.active) .arrow-down:before, .device-desktop .menu-link:not(.active) .arrow-down:after {
    transition: background-color .2s ease; }
  .device-desktop .menu-link:not(.active):hover {
    color: #0e9c97; }
    @media screen and (max-width: 999px) {
      .device-desktop .menu-link:not(.active):hover .arrow-down:before, .device-desktop .menu-link:not(.active):hover .arrow-down:after {
        background-color: #0e9c97; } }

@media screen and (max-width: 999px) {
  .sub-menu {
    height: 0;
    overflow: hidden;
    margin-top: -1px; } }

@media screen and (min-width: 1000px) {
  .sub-menu {
    position: absolute;
    min-width: 100%;
    top: 91px;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: all .2s ease;
    border-radius: 0 0 5px 5px;
    overflow: hidden; } }

.sub-menu li {
  display: block; }

.sub-menu-link {
  display: block;
  padding: 15px 25px;
  min-width: 100%;
  color: #ffffff;
  background-color: #03639f;
  text-decoration: none;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  width: max-content; }
  @media screen and (min-width: 1000px) {
    .sub-menu-link {
      padding: 10px 25px; } }
  .device-desktop .sub-menu-link {
    transition: background-color .2s ease; }
    .device-desktop .sub-menu-link:hover {
      background-color: #2779ad; }

.articles-amount {
  text-align: center;
  margin-bottom: 10px; }

.pagination {
  text-align: center; }

.pagination-anchor {
  color: #0e9c97;
  text-decoration: none;
  display: inline-block;
  padding: 0 3px; }
  .pagination-anchor.is-active {
    color: #202a38;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-style: normal; }
  .device-desktop .pagination-anchor {
    transition: color .2s ease; }
    .device-desktop .pagination-anchor .arrow:before, .device-desktop .pagination-anchor .arrow:after {
      transition: background-color .2s ease; }
    .device-desktop .pagination-anchor:hover {
      color: #03639f; }
      .device-desktop .pagination-anchor:hover .arrow:before, .device-desktop .pagination-anchor:hover .arrow:after {
        background-color: #03639f; }

.pagination-filler {
  color: #0e9c97;
  display: inline-block;
  padding: 0 5px; }

.pagination-prev {
  margin-right: 15px;
  text-align: left; }
  .pagination-prev .arrow {
    display: inline-block;
    position: relative;
    height: 10px;
    margin-left: 10px; }
    .pagination-prev .arrow:before, .pagination-prev .arrow:after {
      content: '';
      display: block;
      width: 7px;
      height: 1px;
      position: absolute;
      right: 0;
      background-color: #0e9c97; }
    .pagination-prev .arrow:before {
      top: 5px;
      transform-origin: left top;
      transform: rotate(-45deg); }
    .pagination-prev .arrow:after {
      bottom: 5px;
      transform-origin: left bottom;
      transform: rotate(45deg); }

.pagination-next {
  margin-left: 15px;
  text-align: right; }
  .pagination-next .arrow {
    display: inline-block;
    position: relative;
    height: 10px;
    margin-left: 10px; }
    .pagination-next .arrow:before, .pagination-next .arrow:after {
      content: '';
      display: block;
      width: 7px;
      height: 1px;
      position: absolute;
      right: 0;
      background-color: #0e9c97; }
    .pagination-next .arrow:before {
      top: 0;
      transform-origin: left top;
      transform: rotate(45deg); }
    .pagination-next .arrow:after {
      bottom: 0;
      transform-origin: left bottom;
      transform: rotate(-45deg); }

aside .block:first-child {
  margin-top: 0; }

aside .block-wrapper {
  margin: 30px 25px 10px 25px; }
  @media screen and (min-width: 1000px) {
    aside .block-wrapper:first-child {
      margin: 0 0 30px 0; } }

#preloader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #ffffff;
  z-index: 999; }

.block-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.block {
  margin: 15px 0;
  width: 100%;
  padding: 15px 20px 20px 20px;
  background-color: #f5f5f5;
  position: relative; }
  .block.toc {
    background-color: #edecec; }
  @media screen and (min-width: 1000px) {
    .block {
      padding: 25px 30px 30px 30px; } }
  @media screen and (min-width: 600px) {
    .block.has-buttons {
      padding-bottom: 75px; } }
  @media screen and (min-width: 1000px) {
    .block.has-buttons {
      padding-bottom: 90px; } }
  .block.light {
    background-color: #ffffff; }
  .block.full-width {
    margin: 10px 0;
    padding: 15px 0 30px 0;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2); }
    @media screen and (min-width: 1000px) {
      .block.full-width {
        padding: 25px 0 40px 0; } }
  @media screen and (min-width: 1000px) {
    .block.large-50 {
      width: calc(50% - 15px); }
    .block.large-33 {
      width: calc(33% - 15px); } }

.date {
  text-transform: uppercase;
  font-size: 1.1rem;
  line-height: 1.1rem;
  letter-spacing: 2px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-style: normal; }
  .date .year {
    position: relative;
    padding-left: 15px;
    margin-left: 5px;
    display: inline-block; }
    .date .year:before {
      content: '';
      display: block;
      width: 2px;
      height: 2px;
      background-color: #202a38;
      position: absolute;
      top: 5px;
      left: 0; }

.block-title {
  margin: 0 0 15px 0;
  display: block;
  position: relative;
  padding-bottom: 15px; }
  @media screen and (min-width: 1000px) {
    .block-title {
      margin-bottom: 25px;
      padding-bottom: 25px; } }
  .block-title:not(:first-child) {
    margin-top: 10px; }
    @media screen and (min-width: 1000px) {
      .block-title:not(:first-child) {
        margin-top: 15px; } }
  .block-title:after {
    content: '';
    height: 2px;
    width: 60px;
    position: absolute;
    background-color: #e85811;
    left: 0;
    bottom: 0; }
  .block-title.has-comments {
    position: relative;
    padding-right: 50px; }
    @media screen and (min-width: 1000px) {
      .block-title.has-comments {
        padding-right: 60px; } }

.article-comments-count {
  display: block;
  position: absolute;
  top: -15px;
  right: 0; }
  @media screen and (min-width: 1000px) {
    .article-comments-count {
      top: -25px; } }
  .article-comments-count .count {
    position: absolute;
    width: 100%;
    height: 100%;
    line-height: 32px;
    text-align: center;
    color: #e85811;
    font-size: 1.8rem;
    font-family: "Meta Lf", sans-serif;
    font-weight: 500;
    font-style: normal; }

.comment-icon {
  display: block;
  width: 41px;
  height: 42px; }
  .comment-icon path {
    fill: #e85811; }

@media screen and (min-width: 600px) {
  .full-width .block-content {
    display: flex;
    justify-content: space-between; } }

@media screen and (min-width: 600px) {
  .full-width .block-details {
    flex: 70 0px;
    padding-right: 30px; } }

.block-details li {
  display: flex;
  margin-bottom: 10px; }

.block-details .label {
  flex: 1 auto;
  max-width: 120px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-style: normal; }

.block-details .value {
  flex: 3 0; }

.block-controls {
  margin-top: 20px; }
  @media screen and (min-width: 600px) {
    .has-buttons .block-controls {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: absolute;
      bottom: 20px;
      width: calc(100% - 40px); }
      .has-buttons .block-controls .button-show-abstract,
      .has-buttons .block-controls .download {
        margin-bottom: 0; } }
  @media screen and (min-width: 1000px) {
    .has-buttons .block-controls {
      bottom: 30px;
      width: calc(100% - 60px); } }
  @media screen and (min-width: 600px) {
    .full-width .block-controls {
      margin: 0;
      flex: 1 auto; } }
  .block-controls .button-show-abstract,
  .block-controls .download {
    margin-bottom: 15px; }

.article-block-abstract {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
  transition: all .25s ease-out; }
  .mod-show-abstract .article-block-abstract {
    visibility: visible;
    opacity: 1; }
  .article-block-abstract .close-button {
    position: absolute;
    top: 5px;
    right: 15px; }
    @media screen and (min-width: 1000px) {
      .article-block-abstract .close-button {
        top: 20px; } }

.article-block-abstract-content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 15px 40px 0 20px;
  margin-bottom: 25px; }
  @media screen and (min-width: 1000px) {
    .article-block-abstract-content {
      padding: 25px 60px 0 30px; } }
  .article-block-abstract-content .abstract-doaj {
    margin-bottom: 20px; }

.article-block-abstract-title {
  padding-right: 30px;
  margin-bottom: 20px; }

.hero-block {
  background-position: center center;
  background-size: cover;
  padding: 20px;
  position: relative; }
  @media screen and (min-width: 1000px) {
    .hero-block {
      padding: 50px; } }
  .hero-block .content {
    position: relative;
    z-index: 2; }
  .hero-block:before, .hero-block:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .hero-block:before {
    background-color: rgba(0, 0, 0, 0.2); }
  .hero-block:after {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%); }

.hero-title {
  margin-bottom: 20px;
  padding-bottom: 20px;
  text-align: center;
  position: relative; }
  @media screen and (min-width: 1000px) {
    .hero-title {
      margin-bottom: 30px;
      padding-bottom: 30px; } }
  .hero-title:after {
    content: '';
    height: 2px;
    width: 60px;
    background-color: #0e9c97;
    position: absolute;
    left: calc(50% - 30px);
    bottom: 0; }

.hero-introduction {
  text-align: center;
  max-width: 670px;
  margin: 0 auto; }

.flexbox-block:nth-child(1) .block-title:after {
  background-color: #0e9c97; }

.flexbox-block:nth-child(1) .button {
  background-color: #0e9c97; }
  .device-desktop .flexbox-block:nth-child(1) .button:hover {
    background-color: #10b6b0; }

.flexbox-block:nth-child(2) .block-title:after {
  background-color: #03639f; }

.flexbox-block:nth-child(2) .button {
  background-color: #03639f; }
  .device-desktop .flexbox-block:nth-child(2) .button:hover {
    background-color: #2779ad; }

.flexbox-block:nth-child(3) .block-title:after {
  background-color: #e85811; }

.flexbox-block:nth-child(3) .button {
  background-color: #e85811; }
  .device-desktop .flexbox-block:nth-child(3) .button:hover {
    background-color: #ff7a22; }

.download-block:nth-child(3n+1) .block-title:after {
  background-color: #0e9c97; }

.download-block:nth-child(3n+1) .button {
  background-color: #0e9c97; }
  .device-desktop .download-block:nth-child(3n+1) .button:hover {
    background-color: #10b6b0; }

.download-block:nth-child(3n+2) .block-title:after {
  background-color: #03639f; }

.download-block:nth-child(3n+2) .button {
  background-color: #03639f; }
  .device-desktop .download-block:nth-child(3n+2) .button:hover {
    background-color: #2779ad; }

.download-block:nth-child(3n+3) .block-title:after {
  background-color: #e85811; }

.download-block:nth-child(3n+3) .button {
  background-color: #e85811; }
  .device-desktop .download-block:nth-child(3n+3) .button:hover {
    background-color: #ff7a22; }

.download-block .filename {
  display: flex; }
  .download-block .filename .label {
    display: block;
    flex: 1 auto;
    max-width: 80px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-style: normal; }
  .download-block .filename .value {
    display: block;
    flex: 3 0; }

.cookie-wall {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background: rgba(86, 86, 86, 0.95);
  padding: 5px 10px; }
  .cookie-wall.mod-hidden {
    display: none;
    pointer-events: none; }
  .cookie-wall p {
    font-size: 0.9em;
    color: #ffffff;
    text-align: center;
    width: 100%; }
    @media screen and (min-width: 600px) {
      .cookie-wall p {
        margin-right: 20px;
        width: auto; } }
    .cookie-wall p a {
      color: #ffffff; }
  .cookie-wall .button.base {
    font-size: 1.5rem;
    padding: 8px 20px; }

.article-nav {
  max-width: 50%; }
  @media screen and (max-width: 599px) {
    .article-nav {
      margin-top: 15px; } }
  @media screen and (min-width: 600px) {
    .article-nav {
      width: calc(50% - 20px);
      margin: 15px 0; } }
  .article-nav h4 {
    margin-bottom: 10px; }
    .article-nav h4.next {
      text-align: right; }

.prev-link,
.next-link {
  position: relative;
  font-family: "Meta Lf", sans-serif;
  font-weight: 500;
  font-style: normal; }
  @media screen and (min-width: 600px) {
    .prev-link,
    .next-link {
      display: flex;
      height: calc(100% - 30px);
      align-items: center; } }
  .device-desktop .prev-link .arrow-left:before, .device-desktop .prev-link .arrow-left:after,
  .device-desktop .prev-link .arrow-right:before,
  .device-desktop .prev-link .arrow-right:after, .device-desktop
  .next-link .arrow-left:before, .device-desktop
  .next-link .arrow-left:after,
  .device-desktop
  .next-link .arrow-right:before,
  .device-desktop
  .next-link .arrow-right:after {
    transition: background-color .2s ease; }
  .device-desktop .prev-link:hover .arrow-left:before, .device-desktop .prev-link:hover .arrow-left:after,
  .device-desktop .prev-link:hover .arrow-right:before,
  .device-desktop .prev-link:hover .arrow-right:after, .device-desktop
  .next-link:hover .arrow-left:before, .device-desktop
  .next-link:hover .arrow-left:after,
  .device-desktop
  .next-link:hover .arrow-right:before,
  .device-desktop
  .next-link:hover .arrow-right:after {
    background-color: #ffffff; }

.prev-link {
  padding-left: 40px; }
  .prev-link .arrow-left {
    position: absolute;
    top: calc(50% - 6px);
    left: 10px;
    width: 10px;
    height: 12px; }
    .prev-link .arrow-left:before, .prev-link .arrow-left:after {
      content: '';
      display: block;
      width: 9px;
      height: 2px;
      position: absolute;
      left: 2px;
      background-color: #0e9c97; }
    .prev-link .arrow-left:before {
      top: 0;
      transform-origin: right top;
      transform: rotate(-45deg); }
    .prev-link .arrow-left:after {
      top: 12px;
      transform-origin: right bottom;
      transform: rotate(45deg); }

.next-link {
  padding-right: 40px; }
  .next-link .arrow-right {
    position: absolute;
    top: calc(50% - 6px);
    right: 10px;
    width: 10px;
    height: 12px; }
    .next-link .arrow-right:before, .next-link .arrow-right:after {
      content: '';
      display: block;
      width: 9px;
      height: 2px;
      position: absolute;
      left: 2px;
      background-color: #0e9c97; }
    .next-link .arrow-right:before {
      top: 0px;
      transform-origin: left top;
      transform: rotate(45deg); }
    .next-link .arrow-right:after {
      top: 11px;
      transform-origin: left bottom;
      transform: rotate(-45deg); }

.breadcrumb {
  display: none; }
  @media screen and (min-width: 1000px) {
    .breadcrumb {
      display: block;
      padding-bottom: 5px;
      margin: 40px 0 10px 0; } }
  .breadcrumb .column {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .breadcrumb .column p {
      display: inline; }

.breadcrumb-label {
  font-size: 1.3rem;
  line-height: 1.6rem;
  padding-right: 8px; }

.breadcrumb-link {
  text-decoration: none;
  color: #0e9c97;
  font-size: 1.3rem;
  line-height: 1.6rem; }
  .device-desktop .breadcrumb-link:not(.last) {
    transition: color .2s ease; }
    .device-desktop .breadcrumb-link:not(.last):hover {
      text-decoration: underline;
      color: #10b6b0; }
  .breadcrumb-link.last {
    color: #202a38;
    pointer-events: none;
    cursor: default; }

.breadcrumb-divider {
  position: relative;
  display: inline-block;
  width: 8px;
  height: 10px;
  margin: 0 5px; }
  .breadcrumb-divider:before, .breadcrumb-divider:after {
    content: '';
    display: block;
    width: 6px;
    height: 1px;
    position: absolute;
    left: 2px;
    background-color: #202a38; }
  .breadcrumb-divider:before {
    top: 1px;
    transform-origin: left top;
    transform: rotate(45deg); }
  .breadcrumb-divider:after {
    top: 9px;
    transform-origin: left bottom;
    transform: rotate(-45deg); }

@media screen and (min-width: 600px) {
  .article-filter,
  .search-filter {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; } }

@media screen and (min-width: 1000px) {
  .article-filter,
  .search-filter {
    justify-content: flex-start; } }

@media screen and (min-width: 1300px) {
  .article-filter,
  .search-filter {
    justify-content: flex-start; } }

.article-filter fieldset,
.search-filter fieldset {
  display: block;
  margin: 10px 0;
  width: 100%; }
  @media screen and (min-width: 1000px) {
    .article-filter fieldset,
    .search-filter fieldset {
      width: auto;
      margin: 15px 60px 0 0; } }
  @media screen and (min-width: 1300px) {
    .article-filter fieldset,
    .search-filter fieldset {
      margin: 0 0 0 60px; } }
  .article-filter fieldset:last-child,
  .search-filter fieldset:last-child {
    margin-bottom: 0; }
  .article-filter fieldset.filters label,
  .search-filter fieldset.filters label {
    display: block; }
    @media screen and (min-width: 600px) {
      .article-filter fieldset.filters label,
      .search-filter fieldset.filters label {
        display: inline-block; } }
  .article-filter fieldset .select-wrapper,
  .search-filter fieldset .select-wrapper {
    max-width: 180px;
    display: block;
    margin: 5px 0; }
    @media screen and (min-width: 600px) {
      .article-filter fieldset .select-wrapper,
      .search-filter fieldset .select-wrapper {
        display: inline-block;
        margin: 0 5px; } }
    @media screen and (min-width: 1000px) {
      .article-filter fieldset .select-wrapper,
      .search-filter fieldset .select-wrapper {
        max-width: 220px; } }
    .article-filter fieldset .select-wrapper.sort,
    .search-filter fieldset .select-wrapper.sort {
      margin-right: 30px; }

@media screen and (min-width: 1300px) {
  .search-filter fieldset {
    margin: 0 0 0 30px; } }

@media screen and (min-width: 1000px) {
  .search-filter fieldset .select-wrapper {
    max-width: 180px; } }

@media screen and (max-width: 999px) {
  .toc {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    min-height: 46px; } }

@media screen and (min-width: 1000px) {
  .toc {
    max-height: calc(100vh - 120px);
    overflow-y: auto; }
    .sticky-toc .toc {
      position: fixed;
      max-width: 356px;
      top: 110px;
      width: calc(33% - 58px);
      z-index: 2; }
    .toc.sticky-toc-bottom {
      position: absolute;
      top: auto;
      bottom: 0; } }

.toc-title {
  color: #0e9c97; }
  @media screen and (max-width: 999px) {
    .toc-title {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 12px 25px;
      z-index: 2;
      background-color: #f5f5f5; }
      .toc-title.active .arrow {
        transform: rotate(180deg); }
      .toc-title .arrow {
        position: absolute;
        top: calc(50% - 3px);
        right: 20px;
        width: 11px;
        transform-origin: center 3px;
        transition: transform .3s ease-out; }
        .toc-title .arrow:before, .toc-title .arrow:after {
          content: '';
          display: block;
          width: 9px;
          height: 2px;
          position: absolute;
          top: 0;
          background-color: #202a38; }
        .toc-title .arrow:before {
          left: 0;
          transform-origin: left top;
          transform: rotate(45deg); }
        .toc-title .arrow:after {
          right: 0;
          transform-origin: right top;
          transform: rotate(-45deg); } }
  @media screen and (min-width: 1000px) {
    .toc-title {
      margin-bottom: 10px; } }

@media screen and (max-width: 999px) {
  .toc-list {
    visibility: hidden;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    background-color: #f5f5f5;
    opacity: 0;
    transition: all .3s ease-out; }
    .toc-list.open {
      visibility: visible;
      opacity: 1;
      top: 46px;
      box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2); } }

@media screen and (max-width: 999px) {
  .toc-list-item {
    border-top: 1px solid rgba(0, 0, 0, 0.2); } }

.toc-link {
  display: block;
  padding: 10px 25px;
  color: #202a38;
  text-decoration: none;
  font-family: "Meta Lf", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1.8rem; }
  @media screen and (min-width: 1000px) {
    .toc-link {
      padding: 7px 20px;
      border-radius: 3px; } }
  .device-desktop .toc-link {
    transition: color .2s ease; }
    .device-desktop .toc-link:hover:not(.active) {
      color: #0e9c97; }
  .toc-link.active {
    background-color: #0e9c97;
    color: #ffffff; }

.page-title {
  margin: 0 0 25px 0; }

.article-detail .date {
  margin-bottom: 10px; }

@media screen and (max-width: 999px) {
  .article-detail .page-title {
    padding-right: 80px; } }

.article-details,
.contact-details {
  display: block;
  border-bottom: 2px solid #e85811; }
  .article-details li,
  .contact-details li {
    display: flex;
    margin-bottom: 10px; }
    @media screen and (min-width: 600px) {
      .article-details li,
      .contact-details li {
        min-width: 300px; } }
    .article-details li.authors,
    .contact-details li.authors {
      width: 100%;
      margin-bottom: 20px; }
  .article-details .label,
  .contact-details .label {
    flex: 1 auto;
    max-width: 110px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-style: normal; }
  .article-details .value,
  .contact-details .value {
    flex: 3 0; }

@media screen and (min-width: 600px) {
  .article-details {
    display: flex;
    flex-wrap: wrap;
    position: relative; }
    .article-details li {
      width: 100%; }
      .article-details li:last-child {
        padding-bottom: 10px; }
      .article-details li .label {
        max-width: 150px; } }

.contact-details .label {
  max-width: 275px; }

.contact-details li:last-child {
  padding-bottom: 40px; }

.contact-detail-item {
  display: block;
  position: relative;
  padding-left: 40px; }
  .contact-detail-item.phone {
    margin-top: 15px; }
  .contact-detail-item a {
    color: #e85811;
    text-decoration: none; }
    .device-desktop .contact-detail-item a {
      transition: color .2s ease; }
      .device-desktop .contact-detail-item a:hover {
        text-decoration: underline;
        color: #ff7a22; }
  .contact-detail-item svg {
    display: block;
    position: absolute;
    left: 0;
    top: 0; }
    .contact-detail-item svg path {
      fill: #e85811; }
    .contact-detail-item svg.phone-icon {
      width: 24px;
      height: 23px; }
    .contact-detail-item svg.mail-icon {
      width: 27px;
      height: 18px;
      top: 4px; }

@media screen and (max-width: 999px) {
  article.basic-page-detail {
    padding-top: 20px; } }

@media screen and (max-width: 999px) {
  article.has-toc {
    padding-top: 70px; } }

article h2 {
  font-size: 2.5rem;
  line-height: 2.8rem; }
  article h2:not(.no-border) {
    margin: 0 0 5px 0;
    display: block;
    position: relative;
    padding-top: 20px; }
    @media screen and (min-width: 1000px) {
      article h2:not(.no-border) {
        padding-top: 30px; } }
    article h2:not(.no-border):after {
      content: '';
      height: 2px;
      width: 60px;
      position: absolute;
      background-color: #e85811;
      left: 0;
      top: 0; }

article h3 {
  font-size: 2rem;
  line-height: 2.6rem; }

.caption {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
  text-align: right; }

.key-points ul,
.key-points ol {
  margin: 0;
  padding: 0; }
  .key-points ul li,
  .key-points ol li {
    padding: 5px 0 5px 20px;
    list-style: none;
    position: relative; }
    .key-points ul li:before, .key-points ul li:after,
    .key-points ol li:before,
    .key-points ol li:after {
      content: '';
      display: block;
      width: 7px;
      height: 2px;
      position: absolute;
      left: 2px;
      background-color: #e85811; }
    .key-points ul li:before,
    .key-points ol li:before {
      top: 12px;
      transform-origin: left top;
      transform: rotate(45deg); }
    .key-points ul li:after,
    .key-points ol li:after {
      top: 20px;
      transform-origin: left bottom;
      transform: rotate(-45deg); }

.article-tools {
  position: absolute;
  z-index: 10;
  width: 60px;
  top: 60px; }
  @media screen and (max-width: 1379px) {
    .article-tools {
      right: 0;
      border-radius: 4px 0 0 4px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
      transition: width .5s ease;
      background-color: #ffffff; } }
  @media screen and (min-width: 1380px) {
    .article-tools {
      width: 80px;
      top: 15px; }
      .article-tools:not(.sticky) {
        left: -60px; } }
  .article-tools.share {
    width: 80px; }
  .article-tools.sticky {
    position: fixed; }
    @media screen and (max-width: 1379px) {
      .article-tools.sticky {
        right: 0;
        top: 70px; } }
    @media screen and (min-width: 1000px) {
      .article-tools.sticky {
        top: 110px; } }
    @media screen and (min-width: 1380px) {
      .article-tools.sticky {
        margin-left: -85px;
        top: 110px; } }

.tools-toggle {
  margin: 13px auto;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid #e85811;
  transition: background-color .2s ease; }
  @media screen and (min-width: 1380px) {
    .tools-toggle {
      display: none; } }
  .tools-toggle .icon {
    display: block;
    position: relative;
    width: 3px;
    height: 3px;
    background-color: #e85811;
    transition: background-color .2s ease; }
    .tools-toggle .icon:before, .tools-toggle .icon:after {
      content: '';
      display: block;
      width: 3px;
      height: 3px;
      background-color: #e85811;
      transition: background-color .2s ease;
      position: absolute;
      top: 0; }
    .tools-toggle .icon:before {
      left: -5px; }
    .tools-toggle .icon:after {
      right: -5px; }
  .tools-toggle.active {
    background-color: #e85811; }
    .tools-toggle.active .icon {
      background-color: #ffffff; }
      .tools-toggle.active .icon:before, .tools-toggle.active .icon:after {
        background-color: #ffffff; }
  .device-desktop .tools-toggle:hover {
    background-color: #e85811; }
    .device-desktop .tools-toggle:hover .icon {
      background-color: #ffffff; }
      .device-desktop .tools-toggle:hover .icon:before, .device-desktop .tools-toggle:hover .icon:after {
        background-color: #ffffff; }

@media screen and (max-width: 1379px) {
  .tools-content {
    height: 0;
    overflow: hidden; } }

.tool-button {
  margin: 13px auto;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .tool-button svg {
    display: block; }
    .tool-button svg path,
    .tool-button svg polygon,
    .tool-button svg ellipse {
      fill: #e85811; }
  .tool-button.share svg {
    width: 24px;
    height: 24px; }
  .tool-button.print svg {
    width: 24px;
    height: 24px; }
  .tool-button.download {
    margin: 5px auto 15px auto; }
    .tool-button.download svg {
      width: 24px;
      height: 30px; }
  .tool-button.mail {
    margin-bottom: 5px; }
    .tool-button.mail svg {
      width: 26px;
      height: 20px; }
  .tool-button.linkedin {
    margin-top: 0; }
    .tool-button.linkedin svg {
      width: 22px;
      height: 22px; }
  .tool-button.twitter svg {
    width: 23px;
    height: 18px; }
  .tool-button.facebook svg {
    width: 12px;
    height: 27px; }
  .device-desktop .tool-button {
    cursor: pointer; }
    .device-desktop .tool-button svg path,
    .device-desktop .tool-button svg polygon,
    .device-desktop .tool-button svg ellipse {
      transition: fill .2s ease; }
    .device-desktop .tool-button:hover svg path,
    .device-desktop .tool-button:hover svg polygon,
    .device-desktop .tool-button:hover svg ellipse {
      fill: #0e9c97; }

@media screen and (max-width: 1379px) {
  .share-content {
    overflow: hidden;
    height: 0;
    transition: height .3s ease-out;
    background-color: #f5f5f5; }
    .share-content.open {
      height: 193px; } }

@media screen and (min-width: 1380px) {
  .share-content {
    opacity: 0;
    visibility: visible;
    transition: all .2s ease-out;
    background-color: #e85811;
    position: absolute;
    top: 165px;
    left: 15px;
    width: 50px;
    border-radius: 4px;
    pointer-events: none; }
    .share-content:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #e85811 transparent;
      position: absolute;
      top: -10px;
      left: 15px; }
    .share-content .tool-button svg path,
    .share-content .tool-button svg ellipse {
      fill: #ffffff; }
    .share-content .tool-button.mail {
      margin-top: 5px; }
    .device-desktop .share-content .tool-button:hover svg path,
    .device-desktop .share-content .tool-button:hover svg ellipse {
      fill: rgba(255, 255, 255, 0.7); }
    .share-content.open {
      pointer-events: auto;
      opacity: 1;
      visibility: visible;
      top: 155px; } }

.article-comments-form .name,
.article-comments-form .email {
  max-width: 380px; }

.article-comments-form .form-actions {
  margin-top: -10px; }
  .article-comments-form .form-actions .button {
    display: inline-block; }

.article-comments-form input[type='submit'] {
  display: none; }

.comments {
  margin-top: 40px; }

.comment {
  margin: 15px 0 20px 0; }
  @media screen and (min-width: 1000px) {
    .comment {
      margin: 25px 0 30px 0; } }
  .comment:not(:last-child) {
    display: block;
    position: relative;
    padding-bottom: 20px; }
    @media screen and (min-width: 1000px) {
      .comment:not(:last-child) {
        padding-bottom: 30px; } }
    .comment:not(:last-child):after {
      content: '';
      height: 2px;
      width: 60px;
      position: absolute;
      background-color: #d9d9d9;
      left: 0;
      bottom: 0; }

.comment-name {
  margin-bottom: 10px; }

.comment-messsage {
  margin-top: 10px; }

.article-rectification {
  margin: 25px 0; }
  .article-rectification .article-rectification-content p:first-child {
    margin-top: 5px; }

.rectification-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: visible;
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
  transition: all .25s ease-out; }
  .rectification-overlay.mod-hidden {
    visibility: hidden;
    pointer-events: none;
    opacity: 0; }

.rectification-form {
  padding: 20px 25px;
  position: relative;
  background: #ffffff;
  width: 1000px;
  max-width: calc(100% - 50px);
  min-height: 150px;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.1); }
  @media screen and (min-width: 1000px) {
    .rectification-form {
      padding: 30px 35px; } }
  .rectification-form.mod-hidden {
    visibility: hidden;
    pointer-events: none; }
  .rectification-form .close-button {
    position: absolute;
    top: 10px;
    right: 10px; }
  .rectification-form .form-actions {
    margin-top: 20px;
    text-align: right; }
    .rectification-form .form-actions .button {
      display: inline-block; }
  .rectification-form input[type='submit'] {
    display: none; }

.rectification-form-loader {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: #f5f5f5;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all .25s ease-out; }
  .mod-loading .rectification-form-loader {
    visibility: visible;
    opacity: 1; }
  .rectification-form-loader img {
    width: 25px;
    height: 25px; }

.rectification-form-title {
  margin: 0 0 20px 0; }

.js-rectification-form-message {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: #f5f5f5;
  visibility: hidden;
  opacity: 0;
  transition: all .25s ease-out;
  padding: 20px 25px; }
  @media screen and (min-width: 1000px) {
    .js-rectification-form-message {
      padding: 30px 35px; } }
  .mod-message .js-rectification-form-message {
    visibility: visible;
    opacity: 1; }
  .js-rectification-form-message.error {
    color: #d61739; }

.mce-statusbar.mce-container.mce-panel.mce-stack-layout-item.mce-last {
  display: none;
  pointer-events: none; }

.mce-notification-warning {
  display: none;
  pointer-events: none; }

.rectification-form-content fieldset:not(:last-child) {
  margin-bottom: 10px; }

.rectificationDialogue {
  z-index: 50;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.1);
  background-color: #edecec;
  padding: 10px;
  width: 200px; }
  @media screen and (min-width: 1000px) {
    .rectificationDialogue {
      width: 400px; } }
  .rectificationDialogue.mod-hidden {
    visibility: hidden;
    pointer-events: none; }
  .rectificationDialogue h4 {
    width: 100%;
    text-align: center;
    margin-bottom: 10px; }
  .rectificationDialogue a.button.action {
    display: block;
    width: 100%; }
    @media screen and (min-width: 1000px) {
      .rectificationDialogue a.button.action {
        width: calc(50% - 10px); } }
    .rectificationDialogue a.button.action:nth-child(2) {
      background: #0e9c97; }
      .rectificationDialogue a.button.action:nth-child(2):hover {
        background: #10b6b0; }
      @media screen and (max-width: 999px) {
        .rectificationDialogue a.button.action:nth-child(2) {
          margin-bottom: 10px; } }
    .rectificationDialogue a.button.action:nth-child(3) {
      background: #03639f; }
      .rectificationDialogue a.button.action:nth-child(3):hover {
        background: #2779ad; }

.search-bar {
  max-width: 460px;
  margin: 40px auto 20px auto;
  position: relative; }
  .search-bar input[type='text'] {
    padding-right: 38px; }
    @media screen and (min-width: 1000px) {
      .search-bar input[type='text'] {
        padding-right: 45px; } }
  .search-bar.header-search {
    margin: 0;
    max-width: none; }
    .search-bar.header-search input[type='text'] {
      background-color: #ffffff; }

.search-submit-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (min-width: 1000px) {
    .search-submit-button {
      width: 45px;
      height: 45px; } }
  .device-desktop .search-submit-button {
    cursor: pointer; }
    .device-desktop .search-submit-button svg path {
      transition: fill .2s ease; }
    .device-desktop .search-submit-button:hover svg path {
      fill: #03639f; }
  .search-submit-button svg {
    display: block; }
    .search-submit-button svg path {
      fill: #0e9c97; }

.search-results {
  margin-top: 20px; }

.page-ie-old {
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 20px; }
  .page-ie-old #preloader {
    display: none; }
  .page-ie-old footer,
  .page-ie-old .js-footer,
  .page-ie-old .main-menu,
  .page-ie-old .menu-icon,
  .page-ie-old .menu-toggle {
    display: none; }
  .page-ie-old .menu-toggle {
    display: none; }
  .page-ie-old .home-link {
    display: block;
    left: 30px;
    width: 155px;
    height: 80px;
    background-image: url("../images/npfo-logo-fallback.png"); }
    .page-ie-old .home-link svg,
    .page-ie-old .home-link .tagline {
      display: none; }
  .page-ie-old h1 {
    font-size: 30px;
    line-height: 36px; }
  .page-ie-old .column {
    max-width: none;
    width: 100%;
    height: 100px; }
    .page-ie-old .column.content {
      max-width: 700px;
      border: none; }
  .page-ie-old #browsers {
    margin-top: 50px; }
    .page-ie-old #browsers a {
      display: inline-block;
      margin: 0 10px;
      outline: none;
      border: none; }
      .page-ie-old #browsers a:after {
        display: none; }
      .page-ie-old #browsers a img {
        border: none;
        outline: none; }
      .page-ie-old #browsers a:hover {
        opacity: .75; }

@media print {
  .message-wrapper,
  .breadcrumb,
  .main-menu,
  .menu-icon,
  .article-tools,
  .article-comments,
  .article-rectifications,
  .article-downloads,
  .article-prev-next,
  .article-aside,
  footer {
    display: none; }
  body {
    font-size: 10pt;
    line-height: 18px; }
  h1 {
    font-size: 16pt; }
  h2,
  article h2 {
    padding: 0;
    margin: 0;
    font-size: 14pt; }
  h3 {
    font-size: 12pt; }
  h4,
  h5,
  h6 {
    font-size: 10pt; }
  p {
    margin: 10px 0; }
  #root {
    padding: 0 !important; }
  header {
    border: 0;
    height: 100px;
    position: relative !important;
    top: auto;
    left: auto; }
  .home-link .tagline {
    display: block;
    top: 12px;
    left: 85px; }
  ul:not(.plain) {
    padding: 0 0 0 20px; }
    ul:not(.plain) li {
      padding-left: 0;
      list-style: disc; }
      ul:not(.plain) li:before {
        display: none; }
  ul.plain li {
    list-style-type: none; }
  #root,
  #content {
    width: 100% !important; }
  article {
    width: 100% !important; }
    article a:after {
      content: " [" attr(href) "] ";
      font-size: 8pt;
      color: #c7c9cd; }
    article a.button {
      display: none; }
  img {
    max-width: 100%;
    page-break-inside: avoid; } }
