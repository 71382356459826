// base styling
html {
  //this causes you to put pixels easily in rem - 24px = 2.4rem
  font-size: 62.5%;
  height:100%;

  //Smaller header font on mobile on client request
  @include breakpoint($smallDown){
    font-size: 55%;
  }
}

body {
  height:100%;
  background: $bg-color;
  color: $font-color;
  position: relative; //For absolute positioned elements within

  @include font();
  font-size: 1.6rem;
  line-height: 2.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

#root{
  min-height: 100%;
  position: relative; //For absolute positioned elements within

  .sticky-header & {
    padding-top: 50px;

    @include breakpoint($largeUp) {
      padding-top: 93px;
      padding-bottom: 260px;
    }
  }
}

main {
  min-height: 400px;

  section {
    &:last-child {
      margin-bottom: 0;
    }
  }

}

.column {
  position: relative;
  width: 100%;
  max-width: 1290px;
  margin: 0 auto;
  padding: 0 25px;
}
.article-two-column {
  padding: 0;

  @include breakpoint($largeUp) {
    padding: 30px 25px 0 25px;
    display: flex;
    justify-content: space-between;
  }

  aside {
    z-index: 2;

    @include breakpoint($largeUp) {
      max-width: 370px;
      width: calc(33% - 40px);
    }
  }

  article {
    padding: 0 25px;

    @include breakpoint($largeUp) {
      padding: 0;
      max-width: 800px;
      width: calc(67% - 40px);
    }
  }
}

b,
strong {
  @include font(main-bold);
}

em {
  @include font(main-italic);
}

strong em,
b em,
em strong,
em b {
  @include font(main-bolditalic);
}

h1,
h2,
h3,
h4 {
  @include font(heading);
}

a {
}

p {
  margin: 1rem 0;
}

pre,
code {
  @include font(italic);
  font-size: 1.6rem;
  padding: 0;
  display: block;
  white-space: normal;
}

::selection {
  color: $font-color-light;
  background: $action-color;
}
::-moz-selection {
  color: $font-color-light;
  background: $action-color;
}

ul:not(.plain) {
  margin: 1rem 0;
  padding: 0;

  > li {
    list-style: none;
    position: relative;
    padding-left: 15px;

    &:before {
      content: '';
      display: block;
      width: 4px;
      height: 4px;
      background-color: $font-color;
      border-radius: 4px;
      position: absolute;
      top: 10px;
      left: 0;
    }
  }

  ul {
    margin-left: 5px;

    li {
      &:before {
        border-radius: 0;
        width: 6px;
        height: 2px;
        top: 12px;
      }
    }
  }
}

ul.plain {
  margin: 0;
  padding: 0;
  list-style: none;
}

ol {
  margin: 1rem 0;
  padding: 0 0 0 25px;
}

.table-wrapper {
  margin: 2rem 0;
  display: block;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
button {
  padding: 0;
}

.toc-link{
  p{
    margin: 0px;
  }
}
object {
  max-width: 100%;
}

*,
*:before,
*:after {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.hidden {
  display: none;
}

sub, sup{
  line-height: 0;
}
