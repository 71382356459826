form {

}


fieldset {
  border: none;
  outline: none;
  appearance: none;
  position: relative;
}

.form-item-wrapper {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include breakpoint($largeUp) {
    margin: 10px 0 30px 0;

  }

  .form-item {
    margin: 0 0 25px 0;

    @include breakpoint($largeUp) {
      margin: 0;
    }

    &:last-child {
      margin: 0;
    }
  }
}

.form-item {
  position: relative;
  width: 100%;
  margin: 25px 0;

  @include breakpoint($largeUp) {
    &.large-50 {
      width: calc(50% - 15px);
    }
  }
}

.form-text {
  display: block;
  text-align: right;
  font-size: 1.4rem;
}

input[type='text'],
input[type='email'] {
  background-color: $bg-color;
  border-radius: 3px;
  border: 2px solid $border-color-light;

  appearance: none;
  outline: none;
  font-size: 1.6rem;
  line-height: 1.6rem;
  width: 100%;
  padding: 10px 15px;
  @include font();
  transition: border-color .2s ease;

  &.search-input {
    background-color: $bg-color2;
    border: none;
    height: 45px;

    @include breakpoint($largeUp) {
      padding: 11px 25px;
    }
  }
}

textarea {
  background-color: $bg-color;
  border-radius: 3px;
  border: 2px solid $border-color-light;

  appearance: none;
  outline: none;
  font-size: 1.6rem;
  line-height: 1.6rem;
  width: 100%;
  padding: 10px 15px;
  resize: none;
  height: 150px;
  @include font();
  transition: border-color .2s ease;
}

select {
  appearance: none;
  background-color: $bg-color2;
  border-radius: 3px;
  outline: none;
  border: none;
  color: $primary-color;
  font-size: 1.6rem;
  line-height: 1.9rem;
  padding: 10px 15px;
  width: 100%;
  position: relative;

  @include breakpoint($largeUp) {
    padding: 12px 20px;
  }

}

.select-wrapper {
  display: inline-block;
  position: relative;

  .device-desktop & {
    //cursor: pointer;

    &:before,
    &:after {
      transition: background-color .2s ease;
    }

    select {
      transition: color .2s ease;
    }

    &:hover {
      &:before,
      &:after {
        background-color: $secondary-color;
      }

    &.sort {
      &:hover {
        &:before,
        &:after {
          background-color: white;
        }
      }
    }

      select {
        color: $secondary-color;
      }
    }
  }

  select {
    padding-right: 40px;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    width: 7px;
    height: 1px;
    position: absolute;
    top: calc(50% - 2px);
    background-color: $primary-color;
    z-index: 2;
  }
  &:before {
    right: 22px;
    transform-origin: left top;
    transform: rotate(45deg);
  }
  &:after {
    right: 20px;
    transform-origin: right top;
    transform: rotate(-45deg);
  }

}
textarea:invalid,
input:invalid {
  box-shadow: none;
  outline: none;
}


select::-ms-expand {
  display: none;
}

::-webkit-input-placeholder {
  @include font(main-italic);
  color: $font-color;
  transition: color .2s ease-out;
}

:-moz-placeholder { /* Firefox 18- */
  @include font(main-italic);
  color: $font-color;
  transition: color .2s ease-out;
}

::-moz-placeholder { /* Firefox 19+ */
  @include font(main-italic);
  color: $font-color;
  transition: color .2s ease-out;
}

:-ms-input-placeholder {
  @include font(main-italic);
  color: $font-color;
  transition: color .2s ease-out;
}

:focus::-webkit-input-placeholder {
  color: transparent;
}

:focus::-moz-placeholder {
  color: transparent;
}

:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px white inset;
  border-color: #dedfe1 !important;
}
