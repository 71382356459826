h1 {
  font-size: 2.6rem;
  line-height: 2.9rem;


  @include breakpoint($largeUp) {
    font-size: 3.0rem;
    line-height: 3.6rem;
  }
}

h2 {
  font-size: 2.6rem;
  line-height: 2.9rem;


  @include breakpoint($largeUp) {

    font-size: 3.0rem;
    line-height: 3.6rem;
  }
}

h3 {
  font-size: 2.0rem;
  line-height: 2.4rem;


  @include breakpoint($largeUp) {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }
}

h4 {
  font-size: 1.8rem;
  line-height: 2.2rem;

  @include breakpoint($largeUp) {
    font-size: 2.0rem;
    line-height: 2.6rem;
  }
}

.text-light {
  color: $font-color-light;
}
