* {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

button {
  -webkit-appearance: none;
  background: transparent;
  border: none;
  outline: none;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img,
//embed
iframe,
// object,
// audio,
video {
  height: auto;
  max-width: 800px;
  width: auto;
}

iframe {
  border: 0;
}

// table {
//   border-collapse: collapse;
//   border-spacing: 0;
// }

// td,
// th {
//   padding: 0;
//   text-align: left;
// }
