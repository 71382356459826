header {
  position: relative;
  z-index: 20;
  background-color: $bg-color;
  height: 50px;
  border-bottom: 2px solid $primary-color;

  @include breakpoint($largeUp) {
    height: 93px;
  }

  .sticky-header & {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.home-link {
  position: absolute;
  top: 4px;
  left: 5px;
  text-decoration: none;
  color: $font-color;

  @include breakpoint($largeUp) {
    top: 7px;
    left: 25px;
  }

  .logo {
    width: 78px;
    height: 41px;

    @include breakpoint($largeUp) {
      width: 155px;
      height: 80px;
    }
  }
  .tagline {
    display: none;
    position: absolute;
    top: 22px;
    left: 166px;
    font-size: 1.3rem;
    line-height: 1.7rem;
    width: 170px;

    @include breakpoint($headerLogoUp) {
      display: block;
    }
  }
}

.menu-icon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;

  @include breakpoint($largeUp) {
    width: 44px;
    height: 44px;
  }

  svg {
    display: block;

    path {
      fill: $font-color-light;
    }
  }

  .device-desktop & {
    transition: background-color .2s ease;

    &:hover {
      background-color: $primary-color-hover;
    }
  }
}

.menu-toggle {
  position: absolute;
  top: 6px;
  right: 6px;

  .icon {
    position: relative;
    display: block;
    width: 14px;
    height: 2px;
    background-color: $bg-color;
    transition: background-color .2s ease;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 14px;
      height: 2px;
      background-color: $bg-color;
      position: absolute;
      left: 0;
      transition: all .15s ease-out;
    }

    &:before {
      top: -5px;
      transform-origin: left top;
    }
    &:after {
      bottom: -5px;
      transform-origin: left bottom;
    }
  }

  &.active {
    .icon {
      background-color: transparent;

      &:before {
        left: 2px;
        width: 18px;
        top: -6px;
        transform: rotate(45deg);
      }
      &:after {
        left: 2px;
        width: 18px;
        bottom: -6px;
        transform: rotate(-45deg);
      }
    }
  }

  @include breakpoint($largeUp) {
    display: none;
  }


}

.home-button {
  position: absolute;

  //Hide on small desktop screens
  @include breakpoint($headerDown) {
    display: none;
  }

  //Show on mobile/tablet format
  @include breakpoint($largeDown) {
    margin-right: 6px;
    margin-left: 6px;
    display: flex;
    top: 6px;
    right: 45px;
  }

  //Greatness on large desktop screens
  @include breakpoint($headerUp) {
    top: 25px;
    right: 90px;
  }
}

.search-toggle {
  @include breakpoint($largeDown) {
    margin-right: 6px;
    margin-left: 6px;
    position: absolute;
    top: 6px;
    right: 90px;
    //display: none;
  }
  @include breakpoint($largeUp) {
    position: absolute;
    top: 25px;
    right: 25px;
  }
}

.search-menu {
  background-color: $primary-color;
  width: 100%;
  max-width: 520px;
  border-radius: 0 0 5px 5px;
  position: absolute;
  right: 0;
  top: 50px;
  padding: 10px 55px 10px 10px;

  opacity: 0;
  visibility: hidden;
  transition: all .2s ease;

  @include breakpoint($largeUp) {
    right: 17px;
    top: 92px;
  }

  &.open {
    visibility: visible;
    opacity: 1;
  }

  .close-button {
    position: absolute;
    top: 15px;
    right: 10px;
  }
}

