.articles-amount {
  text-align: center;
  margin-bottom: 10px;
}

.pagination {
  text-align: center;
}
.pagination-anchor {
  color: $primary-color;
  text-decoration: none;
  display: inline-block;
  padding: 0 3px;

  &.is-active {
    color: $font-color;
    @include font(main-bold);
  }

  .device-desktop & {
    transition: color .2s ease;

    .arrow {
      &:before,
      &:after {
        transition: background-color .2s ease;
      }
    }

    &:hover {
      color: $secondary-color;

      .arrow {
        &:before,
        &:after {
          background-color: $secondary-color;
        }
      }
    }
  }

}
.pagination-filler {
  color: $primary-color;
  display: inline-block;
  padding: 0 5px;

}
.pagination-prev {
  margin-right: 15px;
  text-align: left;


  .arrow {
    display: inline-block;
    position: relative;
    height: 10px;
    margin-left: 10px;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 7px;
      height: 1px;
      position: absolute;
      right: 0;
      background-color: $primary-color;
    }
    &:before {
      top: 5px;
      transform-origin: left top;
      transform: rotate(-45deg);
    }
    &:after {
      bottom: 5px;
      transform-origin: left bottom;
      transform: rotate(45deg);
    }
  }
}
.pagination-next {
  margin-left: 15px;
  text-align: right;

  .arrow {
    display: inline-block;
    position: relative;
    height: 10px;
    margin-left: 10px;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 7px;
      height: 1px;
      position: absolute;
      right: 0;
      background-color: $primary-color;
    }
    &:before {
      top: 0;
      transform-origin: left top;
      transform: rotate(45deg);
    }
    &:after {
      bottom: 0;
      transform-origin: left bottom;
      transform: rotate(-45deg);
    }
  }

}
