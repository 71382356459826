.toc {
  @include breakpoint($largeDown) {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    min-height: 46px;
  }

  @include breakpoint($largeUp) {
    max-height: calc(100vh - 120px);
    overflow-y: auto;

    .sticky-toc & {
      position: fixed;
      max-width: 356px;
      top: 110px;
      width: calc(33% - 58px);
      z-index: 2;
    }
    &.sticky-toc-bottom {
      position: absolute;
      top: auto;
      bottom: 0;
    }
  }
}

.toc-title {
  color: $primary-color;

  @include breakpoint($largeDown) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 12px 25px;
    z-index: 2;
    background-color: $bg-color2;

    &.active {
      .arrow {
        transform: rotate(180deg);
      }
    }

    .arrow {
      position: absolute;
      top: calc(50% - 3px);
      right: 20px;
      width: 11px;
      transform-origin: center 3px;
      transition: transform .3s ease-out;

      &:before,
      &:after {
        content: '';
        display: block;
        width: 9px;
        height: 2px;
        position: absolute;
        top: 0;
        background-color: $font-color;
      }
      &:before {
        left: 0;
        transform-origin: left top;
        transform: rotate(45deg);
      }
      &:after {
        right: 0;
        transform-origin: right top;
        transform: rotate(-45deg);
      }
    }
  }
  @include breakpoint($largeUp) {
    margin-bottom: 10px;
  }

}

.toc-list {

  @include breakpoint($largeDown) {
    visibility: hidden;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    background-color: $bg-color2;
    opacity: 0;

    transition: all .3s ease-out;

    &.open {
      visibility: visible;
      opacity: 1;
      top: 46px;
      box-shadow: 0 5px 5px 0 rgba(0,0,0,0.2)
    }
  }
}

.toc-list-item {

  @include breakpoint($largeDown) {
    border-top: 1px solid $border-color-20;
  }
}

.toc-link {
  display: block;
  padding: 10px 25px;
  color: $font-color;
  text-decoration: none;
  @include font(heading);
  font-size: 1.8rem;

  @include breakpoint($largeUp) {
    padding: 7px 20px;
    border-radius: 3px;
  }

  .device-desktop & {
    transition: color .2s ease;

    &:hover {
      &:not(.active){
        color: $primary-color;
      }
    }
  }

  &.active {
    background-color: $primary-color;
    color: $font-color-light
  }
}
