.article-blocks-container {
}

.article-block {
}

.article-block-title {
}


.article-block-abstract {
  position: absolute;
  top: 0;
  left: 0;
  background-color: $bg-color2;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
  transition: all .25s ease-out;

  .mod-show-abstract & {
    visibility: visible;
    opacity: 1;
  }

  .close-button {
    position: absolute;
    top: 5px;
    right: 15px;

    @include breakpoint($largeUp) {
      top: 20px;
      //right: 20px;
    }
  }
}
.article-block-abstract-content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 15px 40px 0 20px;
  margin-bottom: 25px;

  @include breakpoint($largeUp) {
    padding: 25px 60px 0 30px;
  }
  .abstract-doaj {
    margin-bottom: 20px;
  }
}

.article-block-abstract-title {
  padding-right: 30px;
  margin-bottom: 20px;
}
