.message-wrapper,
.breadcrumb,
.main-menu,
.menu-icon,
.article-tools,
.article-comments,
.article-rectifications,
.article-downloads,
.article-prev-next,
.article-aside,
footer {
  display: none;
}


//font sizes
body {
  font-size: 10pt;
  line-height: 18px;
}

h1 {
  font-size: 16pt;
}

h2,
article h2 {
  padding: 0;
  margin: 0;
  font-size: 14pt;
}

h3 {
  font-size: 12pt;
}

h4,
h5,
h6 {
  font-size: 10pt;
}

p {
  margin: 10px 0;
}


//specific styling

#root {
  padding: 0 !important;
}

header {
  border: 0;
  height: 100px;
  position: relative !important;
  top: auto;
  left: auto;
}

.home-link .tagline {
  display: block;
  top: 12px;
  left: 85px;
}

ul:not(.plain) {
  padding: 0 0 0 20px;

  li {
    padding-left: 0;
    list-style: disc;

    &:before {
      display: none;
    }
  }
}
ul.plain {
  li {
    list-style-type: none;
  }
}

#root,
#content {
  width: 100% !important;
}

article {
  width: 100% !important;

  a {
    &:after {
      content: " [" attr(href) "] ";
      font-size: 8pt;
      color: #c7c9cd; //rgba doesn't work
    }
    &.button {
      display: none;
    }
  }
}

img {
  max-width: 100%;
  page-break-inside: avoid;
}



