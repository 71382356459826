.page-ie-old {
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;

  #preloader {
    display: none;
  }

  footer,
  .js-footer,
  .main-menu,
  .menu-icon,
  .menu-toggle {
    display: none;
  }
  .menu-toggle {
    display: none;
  }

  .home-link {
    display: block;
    left: 30px;
    width: 155px;
    height: 80px;
    background-image: url('../images/npfo-logo-fallback.png');

    svg,
    .tagline {
      display: none;
    }
  }

  h1 {
    font-size: 30px;
    line-height: 36px;
  }

  .column {
    max-width: none;
    width: 100%;
    height: 100px;

    &.content{
      max-width: 700px;
      border: none;
    }
  }

  #browsers {
    margin-top: 50px;

    a {
      display: inline-block;
      margin: 0 10px;
      outline: none;
      border: none;

      &:after {
        display: none;
      }

      img {
        border: none;
        outline: none;
      }

      &:hover {
        opacity: .75;
      }
    }
  }
}
