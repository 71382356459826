.cookie-wall{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background: rgba(86, 86, 86, 0.95);
  padding: 5px 10px;

  &.mod-hidden{
    display: none;
    pointer-events: none;
  }

  p {
    font-size: 0.9em;
    color: $font-color-light;
    text-align: center;
    width: 100%;

    @include breakpoint($mediumUp) {
      margin-right: 20px;
      width: auto;
    }

    a {
      color: $font-color-light;
    }
  }

  .button.base{
    font-size: 1.5rem;
    padding: 8px 20px;
  }
}
