.rectificationDialogue{
  z-index: 50;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  box-shadow: 0 0 40px 5px rgba(0,0,0,0.1);
  background-color: $bg-color3;
  padding: 10px;
  width: 200px;

  @include breakpoint($largeUp){
    width: 400px;
  }

  &.mod-hidden {
    visibility: hidden;
    pointer-events: none;
  }

  h4{
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  a.button.action{
    display: block;
    width: 100%;

    @include breakpoint($largeUp){
      width: calc(50% - 10px);
    }

    &:nth-child(2){
      background: $primary-color;

      &:hover{
        background: $primary-color-hover;
      }

      @include breakpoint($largeDown){
        margin-bottom: 10px;
      }
    }

    &:nth-child(3){
      background: $secondary-color;

      &:hover{
        background: $secondary-color-hover;
      }
    }
  }
}