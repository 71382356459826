// paths
//knmp.nl way, the 'normal' way won't work

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSansBlackItalic.eot");
  src: url("../fonts/OpenSansBlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansBlackItalic.woff") format("woff"), url("../fonts/OpenSansBlackItalic.ttf") format("truetype"), url("../fonts/OpenSansBlackItalic.svg#OpenSansBlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+000D-25CA;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSansBold.eot");
  src: url("../fonts/OpenSansBold.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansBold.woff") format("woff"), url("../fonts/OpenSansBold.ttf") format("truetype"), url("../fonts/OpenSansBold.svg#OpenSansBold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+000D-25CA;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSansBoldItalic.eot");
  src: url("../fonts/OpenSansBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansBoldItalic.woff") format("woff"), url("../fonts/OpenSansBoldItalic.ttf") format("truetype"), url("../fonts/OpenSansBoldItalic.svg#OpenSansBoldItalic") format("svg");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+000D-25CA;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSansSemibold.eot");
  src: url("../fonts/OpenSansSemibold.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansSemibold.woff") format("woff"), url("../fonts/OpenSansSemibold.ttf") format("truetype"), url("../fonts/OpenSansSemibold.svg#OpenSansSemibold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+000D-25CA;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSansSemiboldItalic.eot");
  src: url("../fonts/OpenSansSemiboldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansSemiboldItalic.woff") format("woff"), url("../fonts/OpenSansSemiboldItalic.ttf") format("truetype"), url("../fonts/OpenSansSemiboldItalic.svg#OpenSansSemiboldItalic") format("svg");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+000D-25CA;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSansLight.eot");
  src: url("../fonts/OpenSansLight.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansLight.woff") format("woff"), url("../fonts/OpenSansLight.ttf") format("truetype"), url("../fonts/OpenSansLight.svg#OpenSansLight") format("svg");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+000D-25CA;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSansLightItalic.eot");
  src: url("../fonts/OpenSansLightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansLightItalic.woff") format("woff"), url("../fonts/OpenSansLightItalic.ttf") format("truetype"), url("../fonts/OpenSansLightItalic.svg#OpenSansLightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+000D-25CA;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans.eot");
  src: url("../fonts/OpenSans.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans.woff") format("woff"), url("../fonts/OpenSans.ttf") format("truetype"), url("../fonts/OpenSans.svg#OpenSans") format("svg");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+000D-25CA;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSansItalic.eot");
  src: url("../fonts/OpenSansItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansItalic.woff") format("woff"), url("../fonts/OpenSansItalic.ttf") format("truetype"), url("../fonts/OpenSansItalic.svg#OpenSansItalic") format("svg");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+000D-25CA;
}

@font-face {
  font-family: "Meta Lf";
  src: url("../fonts/MetaLF.eot");
  src: url("../fonts/MetaLF.eot?#iefix") format("embedded-opentype"), url("../fonts/MetaLF.woff2") format("woff2"), url("../fonts/MetaLF.woff") format("woff"), url("../fonts/MetaLF.svg#MetaLF") format("svg");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

