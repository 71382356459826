.flexbox-blocks-container {
}

//should be nicer done...
.flexbox-block {
  &:nth-child(1) {
    .block-title {
      &:after {
        background-color: $primary-color;
      }
    }
    .button {
      background-color: $primary-color;

      .device-desktop & {
        &:hover {
          background-color: $primary-color-hover;
        }
      }
    }
  }
  &:nth-child(2) {
    .block-title {
      &:after {
        background-color: $secondary-color;
      }
    }
    .button {
      background-color: $secondary-color;

      .device-desktop & {
        &:hover {
          background-color: $secondary-color-hover;
        }
      }
    }
  }
  &:nth-child(3) {
    .block-title {
      &:after {
        background-color: $action-color;
      }
    }
    .button {
      background-color: $action-color;

      .device-desktop & {
        &:hover {
          background-color: $action-color-hover;
        }
      }
    }
  }
}

