.message-wrapper {
  margin: 30px 0;

  .successMessage, .errorMessage {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    background-color: $bg-color2;
    padding: 10px 25px;
  }

  .errorMessage {
    color: $font-color-error;
  }
}

.form-message-wrapper {
  position: absolute;
  left: 5px;
  top: -33px;
  height: 33px;
  background-color: $bg-color;
  opacity: 1;
  visibility: visible;
  transition: all .2s ease;
  padding: 10px 15px;
  font-size: 1.4rem;
  line-height: 1.6rem;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.15);

  &.mod-hidden {
    opacity: 0;
    visibility: hidden;
  }

  &.error {
    color: $font-color-error;

    &:not(.mod-hidden) {

      & + input,
      & + textarea {
        border-color: $font-color-error;
      }
    }
  }
}
