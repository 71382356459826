.breadcrumb {
  display: none;

  @include breakpoint($largeUp) {
    display: block;
    padding-bottom: 5px;
    margin: 40px 0 10px 0;
  }

  .column {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & p{
      display: inline;
    }
  }
}

.breadcrumb-label {
  font-size: 1.3rem;
  line-height: 1.6rem;
  padding-right: 8px;
}

.breadcrumb-link {
  text-decoration: none;
  color: $primary-color;
  font-size: 1.3rem;
  line-height: 1.6rem;

  &:not(.last) {
    .device-desktop & {
      transition: color .2s ease;

      &:hover {
        text-decoration: underline;
        color: $primary-color-hover;
      }
    }
  }

  &.last {
    color: $font-color;
    pointer-events: none;
    cursor: default;
  }
}

.breadcrumb-divider {
  position: relative;
  display: inline-block;
  width: 8px;
  height: 10px;
  margin: 0 5px;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 6px;
    height: 1px;
    position: absolute;
    left: 2px;
    background-color: $font-color;
  }
  &:before {
    top: 1px;
    transform-origin: left top;
    transform: rotate(45deg);
  }
  &:after {
    top: 9px;
    transform-origin: left bottom;
    transform: rotate(-45deg);
  }
}
