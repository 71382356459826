.article-nav {
  max-width: 50%;

  @include breakpoint($mediumDown) {
    margin-top: 15px;

  }
  @include breakpoint($mediumUp) {
    width: calc(50% - 20px);
    margin: 15px 0;
  }

  h4 {
    margin-bottom: 10px;

    &.next {
      text-align: right;
    }
  }
}


.prev-link,
.next-link {
  position: relative;
  @include font(heading);

  @include breakpoint($mediumUp) {
    display: flex;
    height: calc(100% - 30px);
    align-items: center;
  }

  .device-desktop & {
    .arrow-left,
    .arrow-right {
      &:before,
      &:after {
        transition: background-color .2s ease;
      }
    }

    &:hover {
      .arrow-left,
      .arrow-right {
        &:before,
        &:after {
          background-color: $font-color-light;
        }
      }
    }
  }
}

.prev-link {
  padding-left: 40px;

  .arrow-left {
    position: absolute;
    top: calc(50% - 6px);
    left: 10px;
    width: 10px;
    height: 12px;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 9px;
      height: 2px;
      position: absolute;
      left: 2px;
      background-color: $primary-color;
    }
    &:before {
      top: 0;
      transform-origin: right top;
      transform: rotate(-45deg);
    }
    &:after {
      top: 12px;
      transform-origin: right bottom;
      transform: rotate(45deg);
    }
  }
}

.next-link {
  padding-right: 40px;

  .arrow-right {
    position: absolute;
    top: calc(50% - 6px);
    right: 10px;
    width: 10px;
    height: 12px;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 9px;
      height: 2px;
      position: absolute;
      left: 2px;
      background-color: $primary-color;
    }
    &:before {
      top: 0px;
      transform-origin: left top;
      transform: rotate(45deg);
    }
    &:after {
      top: 11px;
      transform-origin: left bottom;
      transform: rotate(-45deg);
    }
  }

}

