// imports
@import

// variables
'variables/breakpoints',
'variables/fonts',
'variables/colors',
'variables/animations',

// mixins
'mixins/mediaqueries',
'mixins/typography',
'mixins/helpers',

// global
'global/minireset',
'global/font-faces',
'global/main',

// components
'components/title',
'components/button',
'components/form',

'components/messages',
'components/header',
'components/footer',
'components/section',
'components/nav',
'components/pager',
'components/aside',
'components/preloader',

'components/blocks/block',
'components/blocks/article',
'components/blocks/hero',
'components/blocks/flexbox',
'components/blocks/download',
'components/blocks/cookieWall',
'components/blocks/prev-next',

'components/article/breadcrumb',
'components/article/filter',
'components/article/toc',
'components/article/detail',
'components/article/key-points',
'components/article/tools',
'components/article/comments',
'components/article/rectification',
'components/article/rectificationForm',
'components/article/rectificationDialogue',

'components/search/search-bar',
'components/search/search-results',


// views
'/views/oldbrowser';


// print styling
@media print {
  @import "global/print.scss";
}
