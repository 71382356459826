// general colors
$primary-color: #0e9c97;
$primary-color-20: rgba(14,156,151,0.2);
$primary-color-hover: #10b6b0;
$secondary-color: #03639f;
$secondary-color-20: rgba(3,99,159,0.2);
$secondary-color-hover: #2779ad;
$action-color: #e85811;
$action-color-20: rgba(232,88,17,0.2);
$action-color-hover: #ff7a22;
$font-color: #202a38;
$font-color-second: #262626;
$font-color-light: #ffffff;
$font-color-error: #d61739;
$bg-color: #ffffff;
$bg-color-70: rgba(255,255,255,0.7);
$bg-color-20: rgba(255,255,255,0.2);
$bg-color2: #f5f5f5;
$bg-color3: #edecec;
$bg-color-dark: #202a38;

$border-color-light: #d9d9d9;
$border-color-10: rgba(0,0,0,0.1);
$border-color-20: rgba(0,0,0,0.2);




