
.key-points {
  ul,
  ol {
    margin: 0;
    padding: 0;

    li {
      padding: 5px 0 5px 20px;
      list-style: none;
      position: relative;

      &:before,
      &:after {
        content: '';
        display: block;
        width: 7px;
        height: 2px;
        position: absolute;
        left: 2px;
        background-color: $action-color;
      }
      &:before {
        top: 12px;
        transform-origin: left top;
        transform: rotate(45deg);
      }
      &:after {
        top: 20px;
        transform-origin: left bottom;
        transform: rotate(-45deg);
      }
    }

  }
}
