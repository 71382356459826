//should be nicer done...
.download-block {
  &:nth-child(3n+1) {
    .block-title {
      &:after {
        background-color: $primary-color;
      }
    }
    .button {
      background-color: $primary-color;

      .device-desktop & {
        &:hover {
          background-color: $primary-color-hover;
        }
      }
    }
  }
  &:nth-child(3n+2) {
    .block-title {
      &:after {
        background-color: $secondary-color;
      }
    }
    .button {
      background-color: $secondary-color;

      .device-desktop & {
        &:hover {
          background-color: $secondary-color-hover;
        }
      }
    }
  }
  &:nth-child(3n+3) {
    .block-title {
      &:after {
        background-color: $action-color;
      }
    }
    .button {
      background-color: $action-color;

      .device-desktop & {
        &:hover {
          background-color: $action-color-hover;
        }
      }
    }
  }

  .filename {
    display: flex;

    .label {
      display: block;
      flex: 1 auto;
      max-width: 80px;
      @include font(main-bold);
    }
    .value {
      display: block;
      flex: 3 0;

    }
  }
}

