.articles-counter {

  @include breakpoint($xlargerUp) {
  }
}

.article-filter,
.search-filter {
  @include breakpoint($mediumUp) {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @include breakpoint($largeUp) {
    justify-content: flex-start;
  }
  @include breakpoint($xlargerUp) {
    justify-content: flex-start;
  }
  fieldset {
    display: block;
    margin: 10px 0;
    width: 100%;

    @include breakpoint($largeUp) {
      width: auto;
      margin: 15px 60px 0 0;
    }
    @include breakpoint($xlargerUp) {
      margin: 0 0 0 60px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.filters {
      label {
        display: block;

        @include breakpoint($mediumUp) {
          display: inline-block;
        }
      }
    }

    .select-wrapper {
      max-width: 180px;
      display: block;
      margin: 5px 0;

      @include breakpoint($mediumUp) {
        display: inline-block;
        margin: 0 5px;
      }

      @include breakpoint($largeUp) {
        max-width: 220px;
      }

      &.sort {
        margin-right: 30px;
      }
    }
  }
}

.search-filter {
  fieldset {
    @include breakpoint($xlargerUp) {
      margin: 0 0 0 30px;
    }

    .select-wrapper {
      @include breakpoint($largeUp) {
        max-width: 180px;
      }
    }
  }
}
