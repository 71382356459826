.search-bar {
  max-width: 460px;
  margin: 40px auto 20px auto;
  position: relative;

  input[type='text'] {
    padding-right: 38px;

    @include breakpoint($largeUp) {
      padding-right: 45px;
    }
  }

  &.header-search {
    margin: 0;
    max-width: none;

    input[type='text'] {
      background-color: $bg-color;
    }
  }
}

.search-submit-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include breakpoint($largeUp) {
    width: 45px;
    height: 45px;
  }

  .device-desktop & {
    cursor: pointer;

    svg {
      path {
        transition: fill .2s ease;
      }
    }

     &:hover {
       svg {
         path {
           fill: $secondary-color;
         }
       }
     }
  }

  svg {
    display: block;

    path {
      fill: $primary-color;
    }
  }
}
