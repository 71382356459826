section {
  margin: 25px 0;

  @include breakpoint($largeUp) {
    margin: 40px 0;
  }

  &.light {
    background-color: $bg-color2;
    padding: 25px 0;

    @include breakpoint($largeUp) {
      padding: 40px 0;
    }
  }

  h2 {
    @include breakpoint($largeUp) {
      margin-bottom: 10px;
    }
  }
}

.hero {
  margin-top: 0;

  body.page-home & {
    margin-top: 25px;

    @include breakpoint($largeUp) {
      margin-top: 40px;
    }
  }
}

.article-sections {
  margin-bottom: 20px;

  @include breakpoint($largeUp) {
    margin-bottom: 50px;
  }
  section {
    margin: 20px 0;

    //.open-acces-content {
    //  white-space: nowrap;
    //
    //  a {
    //    word-wrap: break-word;
    //    overflow-wrap: break-word;
    //    hyphens: auto;
    //  }
    //}

    @include breakpoint($largeUp) {
      margin: 30px 0;
    }

    &:first-child {
      h2 {
        padding-top: 0;

        &:after {
          display: none;
        }
      }
    }

    &.article-section-subtitle {
      margin-bottom: 0;

      & + section {
        margin-top: 10px;
      }
    }

  }
  a:not(.button) {
    color: $action-color;
    text-decoration: none;

    .device-desktop & {
      transition: color .2s ease;

      &:hover {
        text-decoration: underline;
        color: $action-color-hover;

      }
    }
  }

  ol,
  ul {
    li {
      &:not(:last-child) {
        padding-bottom: 5px;
      }
    }
  }
  blockquote {
    margin: 15px 0;
    quotes: "\201C""\201D""\2018""\2019";
    @include font(main-italic);
    font-size: 2.0rem;
    line-height: 2.4rem;

    &:before {
      content: open-quote;
      font-size: 4rem;
      line-height: 0.1rem;
      margin-right: 0.25em;
      vertical-align: -0.4em;
    }

    &:after {
      content: close-quote;
      font-size: 4rem;
      line-height: 0.1rem;
      margin-right: 0.25em;
      vertical-align: -0.4em;
    }
    p {

      display: inline;
    }
  }

  .image-wrapper {
    display: inline-block;

    .image {
      line-height: 0;
    }
  }
  .video-wrapper {
    position: relative;
    display: block;
    padding-bottom: 56.25%;
    line-height: 0;
    width: 100%;

    iframe {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  a {
    word-wrap: break-word;
    overflow-wrap: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }
}

.article-section-title {
  margin-bottom: 0;

  & + section:not(.article-section-title) {
    margin-top: 0;
  }
}

.articles-header {
  margin-bottom: 0;
}

.archive-index-content,
.article-index-content,
.search-results {
  margin-top: 0;
}

.latest-articles {
  @include breakpoint($mediumUp) {
    position: relative;
  }
  .all-articles-link {
    @include breakpoint($mediumUp) {
      position: absolute;
      top: 5px;
      right: 25px;
    }

    @include breakpoint($largeUp) {
      top: 10px;
    }
  }
}

.articles-footer {
  padding-bottom: 20px;

  @include breakpoint($largeUp) {
    padding-bottom: 40px;
  }
}

.article-section-video {
  text-align: center;
}

.notfound {
  min-height: 300px;
}
