.button-wrapper {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  .button {
    width: calc(50% - 15px);
  }
}

.button {
  font-size: 1.8rem;
  line-height: 2.0rem;
  display: block;
  cursor: pointer;
  text-decoration: none;
  @include font(heading);
  color: $font-color-light;
  border-radius: 3px;
  padding: 10px 20px;
  text-align: center;

  &.arrow {
    position: relative;
    padding-right: 45px;
  }
  &.download {
    position: relative;
    padding-left: 55px;
  }

  .arrow-down {
    position: absolute;
    top: calc(50% - 3px);
    right: 20px;
    width: 11px;
    background-color: $bg-color-20;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 9px;
      height: 2px;
      position: absolute;
      top: 0;
    }
    &:before {
      left: 0;
      transform-origin: left top;
      transform: rotate(45deg);
    }
    &:after {
      right: 0;
      transform-origin: right top;
      transform: rotate(-45deg);
    }
  }

  .download {
    width: 44px;
    height: 100%;
    border-right: 2px solid $bg-color-20;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 23px;
      height: 19px;

      path {
        fill: $font-color-light;
      }
    }
  }

  .device-desktop & {
    transition: background-color .3s ease, color .3s ease;
  }

  &.base {
    background-color: $primary-color;

    .device-desktop & {
      &:hover {
        background-color: $primary-color-hover;
      }
    }
  }

  &.secondary {
    background-color: $secondary-color;

    .device-desktop & {
      &:hover {
        background-color: $secondary-color-hover;
      }
    }
  }

  &.action {
    background-color: $action-color;

    .device-desktop & {
      &:hover {
        background-color: $action-color-hover;
      }
    }
  }

  &.ghost {
    background-color: transparent;
    padding-top: 8px;
    padding-bottom: 8px;

    .device-desktop & {
      .arrow-down {
        &:before,
        &:after {
          transition: background-color .3s ease;
        }
      }

      .download {
        transition: border-color .3s ease;

        svg {
          path {
            transition: fill .3s ease;
          }
        }
      }
    }

    &.base {
      color: $primary-color;
      border: 2px solid $primary-color;

      .arrow-down {
        &:before,
        &:after {
          background-color: $primary-color;
        }
      }

      .download {
        border-color: $primary-color-20;

        svg {
          path {
            fill: $primary-color;
          }
        }
      }

      .device-desktop & {
        &:hover {
          color: $font-color-light;
          background-color: $primary-color;

          .arrow-down {
            &:before,
            &:after {
              background-color: $font-color-light;
            }
          }
          .download {
            border-color: $bg-color-20;

            svg {
              path {
                fill: $font-color-light;
              }
            }
          }
        }
      }
    }

    &.secondary {
      color: $secondary-color;
      border: 2px solid $secondary-color;

      .arrow-down {
        &:before,
        &:after {
          background-color: $secondary-color;
        }
      }

      .download {
        border-color: $secondary-color-20;

        svg {
          path {
            fill: $secondary-color;
          }
        }
      }

      .device-desktop & {
        &:hover {
          color: $font-color-light;
          background-color: $secondary-color;

          .arrow-down {
            &:before,
            &:after {
              background-color: $font-color-light;
            }
          }
          .download {
            border-color: $bg-color-20;

            svg {
              path {
                fill: $font-color-light;
              }
            }
          }
        }
      }
    }

    &.action {
      color: $action-color;
      border: 2px solid $action-color;

      .arrow-down {
        &:before,
        &:after {
          background-color: $action-color;
        }
      }

      .download {
        border-color: $action-color-20;

        svg {
          path {
            fill: $action-color;
          }
        }
      }

      .device-desktop & {
        &:hover {
          color: $font-color-light;
          background-color: $action-color;

          .arrow-down {
            &:before,
            &:after {
              background-color: $font-color-light;
            }
          }
          .download {
            border-color: $bg-color-20;

            svg {
              path {
                fill: $font-color-light;
              }
            }
          }
        }
      }
    }
  }
}

.text-link {
  text-decoration: none;
  color: $action-color;
  display: inline-block;
  word-break: break-word;
  line-height: 2.2rem;
  border-bottom: 1px solid transparent;
  margin-top: 15px;

  .device-desktop & {
    transition: border-color .3s ease;

    .arrow-right {
      transition: margin .3s ease-out;

    }
    &:hover {
      border-color: $action-color;

      .arrow-right {
        margin-left: 15px;
        margin-right: -5px;
      }
    }
  }

  .arrow-right {
    display: inline-block;
    position: relative;
    height: 10px;
    margin-left: 10px;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 7px;
      height: 1px;
      position: absolute;
      right: 0;
      background-color: $action-color;
    }
    &:before {
      top: 0;
      transform-origin: left top;
      transform: rotate(45deg);
    }
    &:after {
      bottom: 0;
      transform-origin: left bottom;
      transform: rotate(-45deg);
    }
  }

}

.button{
  &:after {
    filter: brightness(100)!important;
    top: 12px !important;
    right: 15px !important;
  }
}

.external-link {
  display: inline-block;
  word-break: break-word;
  position: relative;
  padding-right: 25px;
  margin-right: 5px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    right: 0;
    width: 17px;
    height: 16px;
    background-image: url('../images/icons/external-link.svg');
    background-size: cover;
  }

  &.button-readmore {
    padding-right: 41px;
  }
}

.exclude-external-link {
  display: inline-block;
  margin-top: 10px;
}

.title-link {
  text-decoration: none;
  color: $font-color;
  display: block;

  .device-desktop & {
    transition: color .3s ease;

    &:hover {
      color: $primary-color;
    }
  }
}

.close-button {
  display: block;
  padding: 10px;

  .icon {
    display: block;
    position: relative;
    width: 15px;
    height: 15px;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 23px;
      height: 2px;
      position: absolute;
      top: 0;
      background-color: $font-color;

    }
    &:before {
      left: 0;
      transform-origin: left top;
      transform: rotate(45deg);
    }
    &:after {
      right: 0;
      transform-origin: right top;
      transform: rotate(-45deg);
    }
  }

  &.light {
    .icon {
      &:before,
      &:after {
        background-color: $bg-color;
      }
    }
  }

  .device-desktop & {
    .icon {
      &:before,
      &:after {
        transition: background-color .2s ease;
      }
    }
    &:hover {
      .icon {
        &:before,
        &:after {
          background-color: $primary-color;
        }
      }
      &.light {
        .icon {
          &:before,
          &:after {
            background-color: $bg-color-70;
          }
        }
      }
    }
  }
}

.back-to-top {
  width: 44px;
  height: 44px;

  @include breakpoint($largeDown) {
    position: fixed;
    bottom: 15px;
    right: 15px;

  }
  @include breakpoint($largeUp) {
    position: absolute;
    right: 25px;
    top: 0;
  }
  @include breakpoint($xlargeUp) {
  }
  @include breakpoint($xxlargeUp) {
    right: -50px;
  }
}

.back-to-top-button {
  width: inherit;
  height: inherit;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  border-radius: 3px;
  background-color: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all .3s ease;
  z-index: 50;

  @include breakpoint($largeUp) {
    position: fixed;
    bottom: 25px;
  }
  @include breakpoint($xxlargeUp) {
    bottom: 30px;
  }

  &.visible {
    visibility: visible;
    opacity: 1;
  }

  .arrow-up {
    position: relative;
    display: block;
    width: 14px;
    height: 8px;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 9px;
      height: 2px;
      position: absolute;
      bottom: 0;
      background-color: $font-color-light;
    }
    &:before {
      left: 0;
      transform-origin: left top;
      transform: rotate(-45deg);
    }
    &:after {
      right: 0;
      transform-origin: right top;
      transform: rotate(45deg);
    }
  }

  .device-desktop & {
    transition: background-color .2s ease;

    &:hover {
      background-color: $primary-color-hover;
    }
  }
}
