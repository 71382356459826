.main-menu {
  @include breakpoint($largeDown) {
    position: fixed;
    top: 20px;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    background-color: $bg-color;
    z-index: 100;
    padding-top: 15px;
    visibility: hidden;
    opacity: 0;
    transition: all .15s ease-out;

    &.open {
      visibility: visible;
      opacity: 1;
      top: 50px;
    }
  }

  @include breakpoint($largeUp) {
    display: block;
    float: right;
    margin-right: 60px;
  }

  @include breakpoint($headerUp) {
    margin-right: 130px;
  }

  ul {
    //overflow: hidden;
  }
  li {
    position: relative;

    @include breakpoint($largeUp) {
      display: inline-block;
    }
  }

  .level-1 {
    .device-desktop & {
      &:hover {
        @include breakpoint($largeUp) {
          .sub-menu {
            visibility: visible;
            opacity: 1;
          }
          .menu-link {
            background-color: $secondary-color;
            color: $font-color-light;

            .arrow-down {
              &:before,
              &:after {
                background-color: $bg-color;
              }
            }
          }
        }

      }

    }
  }
}

.menu-link {
  @include font(heading);
  font-size: 1.9rem;
  color: $font-color;
  text-decoration: none;
  display: block;
  transition: background-color .2s ease, color .2s ease;

  @include breakpoint($largeDown) {
    padding: 15px 25px;
  }

  @include breakpoint($largeUp) {
    border-radius: 5px 5px 0 0;
    margin-top: 15px;
    padding: 20px 20px 31px 20px;
  }

  @include breakpoint($xlargeUp) {
    padding: 20px 25px 31px 25px;
  }

  &.active {
    @include breakpoint($largeDown) {
      color: $primary-color;
    }
    @include breakpoint($largeUp) {
      background-color: $bg-color3;
    }
  }

  &.has-submenu {
    cursor: default;

    .label {
      display: inline-block;
      position: relative;
      padding-right: 25px;
    }

    .arrow-down {
      position: absolute;
      top: calc(50% - 3px);
      right: 0;
      width: 11px;
      transition: transform .2s ease-out;
      transform-origin: center 3px;

      &:before,
      &:after {
        content: '';
        display: block;
        width: 9px;
        height: 2px;
        position: absolute;
        top: 0;
        background-color: $font-color;
      }
      &:before {
        left: 0;
        transform-origin: left top;
        transform: rotate(45deg);
      }
      &:after {
        right: 0;
        transform-origin: right top;
        transform: rotate(-45deg);
      }
    }

    &.open {
      background-color: $bg-color2;

      .arrow-down {
        transform: rotate(180deg);
      }
    }

  }

  .device-desktop & {
    &:not(.active) {

      .arrow-down {
        &:before,
        &:after {
          transition: background-color .2s ease;
        }
      }
      &:hover {
        color: $primary-color;

        @include breakpoint($largeDown) {
          .arrow-down {
            &:before,
            &:after {
              background-color: $primary-color;
            }
          }
        }
      }
    }

  }

}

.sub-menu {
  @include breakpoint($largeDown) {
    height: 0;
    overflow: hidden;
    margin-top: -1px;
  }

  @include breakpoint($largeUp) {
    position: absolute;
    //width: 100%;
    min-width: 100%;
    top: 91px;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: all .2s ease;
    border-radius: 0 0 5px 5px;
    overflow: hidden;
  }

  li {
    display: block;

  }
}

.sub-menu-link {
  display: block;
  padding: 15px 25px;
  min-width: 100%;
  color: $font-color-light;
  background-color: $secondary-color;
  text-decoration: none;
  border-top: 1px solid $bg-color-20;
  width: max-content;

  @include breakpoint($largeUp) {
    padding: 10px 25px;
  }

  .device-desktop & {
    transition: background-color .2s ease;

    &:hover {
      background-color: $secondary-color-hover;
    }

  }
}
