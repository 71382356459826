.rectification-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: visible;
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
  transition: all .25s ease-out;

  &.mod-hidden {
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
  }
}

.rectification-form {
  padding: 20px 25px;
  position: relative;
  background: $bg-color;
  width: 1000px;
  max-width: calc(100% - 50px);
  min-height: 150px;
  box-shadow: 0 0 40px 5px rgba(0,0,0,0.1);

  @include breakpoint($largeUp) {
    padding: 30px 35px;
  }

  //When the form is hidden
  &.mod-hidden {
    visibility: hidden;
    pointer-events: none;
  }
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }


  .form-actions {
    margin-top: 20px;
    text-align: right;

    .button {
      display: inline-block;
    }
  }

  input[type='submit'] {
    display: none;
  }
}

.rectification-form-loader {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: $bg-color2;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all .25s ease-out;

  .mod-loading & {
    visibility: visible;
    opacity: 1;
  }

  img {
    width: 25px;
    height: 25px;
  }
}

.rectification-form-content {
}

.rectification-form-title {
  margin: 0 0 20px 0;
}

.js-rectification-form-message {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: $bg-color2;
  visibility: hidden;
  opacity: 0;
  transition: all .25s ease-out;
  padding: 20px 25px;

  @include breakpoint($largeUp) {
    padding: 30px 35px;
  }

  .mod-message & {
    visibility: visible;
    opacity: 1;
  }

  &.success {
  }

  &.error {
    color: $font-color-error;
  }
}


//Hack the TinyMCE editor a bit
.mce-statusbar.mce-container.mce-panel.mce-stack-layout-item.mce-last {
  display: none;
  pointer-events: none;
}

.mce-notification-warning {
  display: none;
  pointer-events: none;
}

.rectification-form-content fieldset:not(:last-child){
  margin-bottom: 10px;
}